import { Fragment } from "react/jsx-runtime";
import styles from "./Table.module.scss";
import { useState } from "react";
import classNames from "classnames";

type TTableProps = {
    columns: string[];
    data: any[];
};

const Table = ({ columns, data }: TTableProps) => {
    const [currentService, setCurrentService] = useState(1);

    return (
        <>
            <ul className={styles.controllerList}>
                {columns.map(
                    (column: string, index: number) =>
                        index > 0 && (
                            <li
                                className={classNames(styles.controllerItem, {
                                    [styles.active]: currentService === index,
                                })}
                                key={index}
                                onClick={() => setCurrentService(index)}
                            >
                                {column}
                            </li>
                        )
                )}
            </ul>
            <table className={styles.table}>
                <thead>
                    <tr>
                        {columns.map((column, index) => (
                            <Fragment key={index}>
                                <th
                                    className={classNames({
                                        [styles.active]: index === 0 || index === currentService,
                                    })}
                                >
                                    {column}
                                </th>
                                {index === 0 && <th className={styles.active}></th>}
                            </Fragment>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => (
                        <tr key={index}>
                            {Object.keys(item).map(
                                (i, internalIndex) =>
                                    i !== "id" && (
                                        <Fragment key={internalIndex}>
                                            <td
                                                className={classNames({
                                                    [styles.active]:
                                                        internalIndex === 0 || internalIndex === currentService,
                                                })}
                                            >
                                                {item[i]}
                                            </td>
                                            {internalIndex === 0 && <td className={styles.active}></td>}
                                        </Fragment>
                                    )
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
};

export default Table;
