export const images = [
    "/images/apparatus/apparatus1.jpg",
    "/images/apparatus/apparatus2.jpg",
    "/images/apparatus/apparatus3.jpg",
    "/images/apparatus/apparatus4.jpg",
    "/images/apparatus/apparatus5.jpg",
    "/images/apparatus/apparatus6.jpg",
    "/images/apparatus/apparatus7.jpg",
    "/images/apparatus/apparatus8.jpg",
    "/images/apparatus/apparatus9.jpg",
    "/images/apparatus/apparatus10.jpg",
    "/images/apparatus/apparatus11.jpg",
    "/images/apparatus/apparatus12.jpg",
    "/images/apparatus/apparatus13.jpg",
    "/images/apparatus/apparatus14.jpg",
    "/images/apparatus/apparatus15.jpg",
    "/images/apparatus/apparatus16.jpg",
    "/images/apparatus/new_apparatus.png",
];