import { Link } from "react-router-dom";
import Breadcrumbs from "../../shared/Breadcrumbs/Breadcrumbs";
import Button from "../../shared/Button/Button";
import Carousel from "../../shared/Carousel/Carousel";
import styles from "./About.module.scss";
import { images } from "./consts";

const About = () => {
  const breadcrumbsList = [
    { name: "Главная", to: "/" },
    { name: "О нас", to: "/about" },
  ];

  return (
    <div className={styles.about}>
      <Breadcrumbs links={breadcrumbsList} />
      <div className={styles.content}>
        <div className={styles.heading}>
          <span className={styles.title}>
            Центр врачебной косметологии «Космомед»
          </span>
          <span className={styles.text}>
            В 2007 году была основана клиника «Космомед», в 2024 году клиника
            уже имеет 3 крупных подразделения в разных районах города. В клинике
            «Космомед» работает более 12 высококвалифицированных
            врачей-косметологов, а также 3 косметолога-эстетиста, 2 гинеколога ,
            специалист УЗИ и эндокринолог. В данный момент для удобства записи к
            нашим специалистам открыт колл-центр и работает онлайн запись.
            Клиника располагает новейшими аппаратами и грамотными врачами, для
            того чтобы быть всегда на страже Вашей красоты, молодости и
            здоровья.
          </span>
        </div>
        <div className={styles.article}>
          <img src="/images/specialists.png" />
          <div>
            <span className={styles.title}>Мы специалисты</span>
            <span className={styles.text}>
              Обратившись к нам — вы получите своевременный, а главное,
              доступный и желаемый результат — здоровую сияющую кожу и ощущение
              абсолютной ухоженности.
            </span>
            <span className={styles.text}>
              Все процедуры проводятся исключительно профессионалами своего дела
              — специалистами самого высочайшего уровня.
            </span>
            <Link to="/employees">
              <Button title="наш персонал" />
            </Link>
          </div>
        </div>
        <div className={styles.virtues}>
          <div className={styles.heading}>
            <span className={styles.title}>Профессиональный подход</span>
            <span className={styles.text}>
              Воспользовавшись нашими профессиональными услугами, вы можете
              получить желаемый вами результат
            </span>
          </div>
          <div className={styles.virtuesList}>
            <div>
              <span className={styles.symbol}>✦</span>
              <span>Профессионалы высочайшего уровня</span>
            </div>
            <div>
              <span className={styles.symbol}>✦</span>
              <span>Современное оборудование</span>
            </div>
            <div>
              <span className={styles.symbol}>✦</span>
              <span>Широкий спектр услуг</span>
            </div>
          </div>
        </div>
        {/* <div className={styles.list}>
                    <span className={styles.title}>Используемая в процедурах косметика</span>
                    <div>
                        <div>
                            <span className={styles.symbol}>✦</span>
                        </div>
                        <div>
                            <span className={styles.symbol}>✦</span>
                        </div>
                        <div>
                            <span className={styles.symbol}>✦</span>
                        </div>
                        <div>
                            <span className={styles.symbol}>✦</span>
                        </div>
                    </div>
                </div> */}

        <div className={styles.list}>
          <span className={styles.title}>Наши аппараты</span>
          <Carousel list={images} />
        </div>
        <div className={styles.article}>
          <img src="/images/services.png" />
          <div>
            <span className={styles.title}>Профессиональная помощь</span>
            <span className={styles.text}>
              Обратившись к нам — вы получите своевременный, а главное,
              доступный и желаемый результат — здоровую сияющую кожу и ощущение
              абсолютной ухоженности.
            </span>
            <span className={styles.text}>
              Все процедуры проводятся исключительно профессионалами своего дела
              — специалистами самого высочайшего уровня.
            </span>
            <Link to="/services">
              <Button title="наши услуги" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
