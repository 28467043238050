import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../../shared/Breadcrumbs/Breadcrumbs";
import Table from "../../shared/Table/Table";
import { services } from "../../utils/consts";
import styles from "./Service.module.scss";

const Service = () => {
  const { id } = useParams();
  const currentService = id
    ? services.find((service) => service.id == Number(id))
    : null;

  const [breadcrumbsList, setBreadcrumbsList] = useState<any[]>([]);

  useEffect(() => {
    if (currentService) {
      setBreadcrumbsList([
        { name: "Главная", to: "/" },
        { name: "Услуги", to: "/services" },
        { name: currentService.name, to: `/services/${currentService.id}` },
      ]);
    }
  }, [currentService]);

  return (
    <div className={styles.service}>
      <Breadcrumbs links={breadcrumbsList} />
      <div className={styles.services}>
        <span className={styles.title}>Наши услуги</span>
        <div>
          <img
            src={currentService?.img}
            className={styles.img}
            alt={currentService?.name}
          />
          <div className={styles.serviceInfo}>
            <span className={styles.name}>{currentService?.name}</span>

            {currentService?.description && (
              <div className={styles.description}>
                {currentService.description
                  .split("\n")
                  .map((line: string, index: number) => (
                    <p key={index}>{line}</p>
                  ))}
              </div>
            )}
          </div>
        </div>

        {/* Таблица услуг */}
        {currentService?.list.length > 0 && (
          <Table
            columns={[
              "Наименование услуги",
              "Кирова",
              "Тернопольская",
              "Ставского",
            ]}
            data={currentService?.list}
          />
        )}
        {/* <div className={styles.executors}>
                <span className={styles.title}>Данную процедуру оказывают</span>
                <div>
                    {employees.map((employee, index) => (
                        <Employee key={index} employee={employee} />
                    ))}
                </div>
            </div> */}
      </div>
    </div>
  );
};

export default Service;
