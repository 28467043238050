export const employees = [
  {
    id: 1,
    name: "Абдулова Алина Витальевна",
    positions: ["Врач-косметолог, дерматолог"],
    description: `Стаж работы более 6 лет

-ботулинотерапия
-биоревитализация
-мезотерапия
-контурная пластика лица
-контурная пластика губ
-профессиональные уходы для кожи лица по косметике HolyLand;GiGi
-химические пилинги
-механические чистки проблемных зон (лицоитело)
-коллагеностимулирующие инъекционные процедуры(AеstheFill,GANAV,Miraline)
-SMAS-лифтинг на аппарате Ulthera(Альтера-терапия),на аппарате Ultraformer MPT
-Morpheus8-фракционная микроигольчатая RF-технология на платформе InMode
-фотоомоложение IPL Lumecca на платформе InMode
-PRP-терапия,плазмолифтинг(Кортексил)
-криолиполиз
-липолитики
-уход по телу,Старвак
-лазерная и восковая эпиляция`,

    education: [],
    img: "/images/doctors/Абдулова Алина Витальевна Врач-косметолог-дерматолог.JPG",
  },
  {
    id: 2,
    name: "Аверченко Инна Сергеевна ",
    positions: ["Врач-косметолог, дерматолог"],
    description: `Стаж работы более 17 лет

-ботулинотерапия
-биоревитализация
-мезотерапия
-контурная пластика лица
-контурная пластика губ
-чистки проблемных зон(лицо и тело)
-профессиональные уходы для кожи лица по косметике HolyLand;GiGi
-индивидуальный подбор косметики для домашнего уход
-коллагеностимулирующие инъекционные процедуры (AеstheFill,GANAV,Miraline)
-SMAS-лифтинг на аппарате Ulthera (Альтера-терапия),на аппарате Ultraformer MPT
-Morpheus8-фракционная микроигольчатая RF-технология на платформе InMode
-фотоомоложение IPL Lumecca на платформе InMode
-PRP-терапия,плазмолифтинг(Кортексил)
`,
    education: [
      '2000-2006 гг - Самарский Государственный Медицинский Университет специальность "Лечебное дело"',
      "2006-2008 гг - Ординатура по специальности анестезиология-реаниматология (Пензенский Институт усовершенствования врачей)",
      '2021-2023 - Ординатура по специальности дерматовенерология (ФГБОУ ВО "Российский биотехнологический Университет" (Росбиотех) г.Москва)',
      '2023 г - Профессиональная переподготовка по программе "Косметология с присвоением квалификации врач-косметолог" (АНО ДПО "Европейский центр")',
      '2022 г - Профессиональная переподготовка по программе "Физическая и реабилитационная медицина" (МУЦ ДПО "Образовательный стандарт"',
    ],
    img: "/images/doctors/Аверченко Инна Сергеевна Врач- косметолог-дерматолог.JPG",
  },
  {
    id: 3,
    name: "Апасова Ольга Алексеевна",
    positions: ["Косметолог-эстетист"],
    education: [],
    description: `Стаж работы более 20 лет

-ведение,лечение пациентов с акне,розацеа,гиперпигментацией,коррекция возрастных изменений кожи
-биоревитализация
-мезотерапия
-RF-лифтинг
-микротоковая терапия
-химические пилинги
-механические чистки проблемных зон(лицо и тело)
-профессиональные уходы для кожи лица по косметике HolyLand;GiGi
-скульптурный массаж лица,Старвак
-индивидуальный подбор косметики для домашнего ухода
`,
    img: "/images/doctors/Апасова Ольга Алексеевна Косметолог эстетист.jpg",
  },
  {
    id: 4,
    name: "Галкина Татьяна Валерьевна",
    positions: ["Врач-дерматолог, косметолог, трихолог"],
    description: `Стаж работы более 10 лет

-лечение акне,постакне,розацеа
-лечение заболеваний кожи и волос
-индивидуальный подбор косметики для домашнего
ухода
-удаление новообразований кожи (родинок,папиллом,бородавок,кератоми тд)
-дерматоскопия
-биопсия кожи
-коррекция рубцов
-ботулинотерапия
-биоревитализация
-мезотерапия
-контурная пластика лица
-контурная пластика губ
-коллагеностимулирующие инъекционные процедуры (AеstheFill,GANAV,Miraline)
-SMAS-лифтинг на аппарате Ulthera(Альтера-терапия),на аппарате Ultraformer MPT
-Morpheus8-фракционная микроигольчатаяRF-технология на платформе InMode
-фотоомоложение IPL Lumecca на платформе InMode
-PRP-терапия,плазмолифтинг(Кортексил)

`,
    education: [],
    img: "/images/doctors/Галкина Татьяна Валерьевна Врач-косметолог-дерматолог-трихолог.PNG",
  },
  {
    id: 5,
    name: "Егорова Елена Николаевна",
    positions: ["Косметолог-эстетист"],
    description: `Стаж работы более 20 лет.

-ведение,лечение пациентов с акне,розацеа,гиперпигментацией,коррекция возрастных изменений кожи
-биоревитализация
-мезотерапия
-RF-лифтинг
-микротоковая терапия
-химические пилинги
-механические чистки проблемных зон(лицо и тело)
-профессиональные уходы для кожи лица по косметике HolyLand;GiGi
-индивидуальный подбор косметики для домашнего ухода
-скульптурный массаж лица,Старвак
`,
    education: [],
    img: "/images/doctors/Егорова Елена Николаевна Косметолог эстетист.JPG",
  },
  {
    id: 6,
    name: "Ильясова Светлана Николаевна",
    positions: ["Гинеколог"],
    description: `Стаж работы более 22 лет.

-проблемы нарушения цикла
-воспалительные заболевания органов малого таза
-проблемы заболеваний шейки
-тактика ведения пациентов при миомах и эндометриозе
-планирование беременности
-специализируется по возрастным проблемам (климактерические нарушения).`,
    education: [
      'Саратовский медицинский университет, клиническая ординатура и интернатура по специальности "Акушерство и гинекология"',
    ],
    img: "/images/doctors/Ильясова Светлана Николаевна врач-гинеколог.JPG",
  },
  {
    id: 7,
    name: "Каргина Ольга Александровна",
    positions: ["Врач-косметолог-дерматолог"],
    description: `Стаж работы более 15 лет

-ботулинотерапия( Диспорт, Ксеомин, Миотокс)
-биоревитализация,мезотерапия
-контурная пластика лица,губ
-коллагеностимулирующие инъекционные процедуры(AestheFill,GANA,Miraline)
-инъекционные методики омоложения "Radiesse"
-Morpheus8-фракционная микроигольчатая RF-технология на платформе InMode
-фотоомоложение IPL Lumecca на платформе InMode
-SMAS - лифтинг на аппарате Ulthera (Альтера-терапия), на аппарате Ultraformer MPT
-нитевой лифтинг Aptos
`,
    education: [
      "2007 г - Пензенский Государсивенный Медицинский институт",
      "Интернатура по терапии в Московском институте последипломногообразования на базе ЦВКГ им. Мондрыка",
      '2008-2010 гг - Проходила подготовку в клинической ординатуре на базе Пензенского клинического центра СВМП по специализации "Дерматовенерология"',
      '2020 г - Повышение квалификации по специальности "Косметология"',
    ],
    img: "/images/doctors/Каргина Ольга Александровна Врач косметолог-дерматолог.jpg",
  },
  {
    id: 8,
    name: "Комиссарова Екатерина Викторовна",
    positions: [
      "Врач превентивной интегративной медицины, эндокринолог, нутрициолог",
    ],
    description: `Стаж работы более 14 лет

-решение проблемы со здоровьем
-подбор рационального,сбалансированного питания
-разбор анализов и определение патологических процессов
-выявление недостатков микроэлементов в организме.
-поиск причины вашего плохого самочувствия или заболевания
-консультации по образу жизни(улучшения качества сна, питьевого режима, питания в целом, подбор микроэлементов, витаминов)
`,
    education: [],
    img: "/images/doctors/Комисорова Екатерина Викторовна Врач эндокринолог-нутрициолог.JPG",
  },
  {
    id: 9,
    name: "Конон Оксана Юрьевна",
    positions: ["Врач-косметолог, дерматолог"],
    description: `Стаж работы более 10 лет
-ботулинотерапия
-биоревитализация,мезотерапия
-контурная пластика лица,губ
-коллагеностимулирующие инъекционные процедуры (AestheFill, GANA, Miraline)
-SMAS - лифтинг на аппарате Ulthera (Альтера-терапия), на аппарате Ultraformer MPT
-Morpheus8-фракционная микроигольчатая
RF-технология на платформе InMode
-фотоомоложение IPL Lumecca
На платформе InMode
-PRP-терапия,плазмолифтинг(Кортексил)
-криолиполизнааппаратеCLATUU
-лазерная эпиляция
-профессиональные уходы для кожи лица
-чистки лица
`,
    education: [
      '2008-2014 гг - ПГУ МИ (Пензенский Государственный Медицинский Институт) специальность "Лечебное дело", диплом с отличием',
      "2014-2016 гг - Ординатура по специальности дерматовенерология (Пензенский Государственный Медицинский Институт)",
      '2018 гг - Профессиональная переподготовка по косметологии для врачей дерматовенерологов (АНО ДПО Центр современных технологий "Перспектива" г. Саранск.',
    ],
    img: "/images/doctors/Конон Оксана Юрьевна Врач косметолог-дерматолог.JPG",
  },
  {
    id: 10,
    name: "Ластовкина Ирина Викторовна ",
    positions: ["Врач косметолог, дерматолог"],
    description: `Стаж работы более 17 лет

-ведение, лечение пациентов с акне,розацеа,гиперпигментацией,коррекция возрастных изменений кожи
-ботулинотерапия
-биоревитализация
-мезотерапия
-контурнаяпластикалица
-контурнаяпластикагуб
-коллагеностимулирующие инъекционные процедуры(AеstheFill,GANAV,Miraline)
-SMAS-лифтинг на аппаратеUlthera(Альтера-терапия),на аппарате Ultraformer MPT
-Morpheus8-фракционная микроигольчатая RF-технология на платформе InMode
-фотоомоложение IPL Lumecca на платформе InMode
-PRP-терапия,плазмолифтинг(Кортексил)
`,
    education: [
      '2005 г - Окончила Медицинский Институт Пензенского Государственного Университета по специальности "Лечебное дело"',
      '2005-2007 гг - проходила обучение в клинической ординатуре по специальности "Дерматовенерология" в Саратовском Государственном Медицинском Университете',
      '2010 г - профессиональная переподготовка по специальности "Косметология" в Российском Университете Дружбы Народов г.Москва',
    ],
    img: "/images/doctors/Ластовкина Ирина Викторовна Врач-косметолог-дерматолог.JPG",
  },
  {
    id: 11,
    name: "Лукьянова Елена Викторовна",
    positions: ["Мастер электроэпиляции, наращивание ресниц, прокол ушей"],
    description: `Стаж работы более 7 лет.

-электроэпиляция на аппарате Depilplus(Испания)
-наращивание и коррекция ресниц
-поверхностные и срединные пилинги на профессиональной косметике Holy Land
-3D массаж лица
-уход по телу,Старвак
-восковая депиляция
-прокол ушей
`,
    education: [
      "1992-1994 гг - Закончила Пензенское медицинское училище номер 2",
      "1999 г - Учебный центр Конто",
    ],
    img: "/images/doctors/Лукьянова Елена Викторовна Электролог-косметолог.JPG",
  },
  {
    id: 12,
    name: "Малкина Елена Владимировна",
    positions: ["Мастер маникюра и педикюра, подолог"],
    description: `Стаж работы более 5 лет
-трещины и гиперкератоз(грубой кожей на подошвенной поверхности стоп)
-вросшие ногти
-грибковые поражения стоп и ногтей
-натоптыши
-стержневые мозоли
-подошвенные бородавки
-утолщённые ногти(онихауксис,онихогрифоз)
-Онихолизис(отслойкой ногтевой пластины от ногтевого ложе) и другие деформациями ногтей
`,
    education: [],
    img: "/images/doctors/Малкина Елена Владимировна подолог.JPG",
  },
  {
    id: 13,
    name: "Митяева Евгения Евгеньевна",
    positions: ["врач-косметолог, дерматолог"],
    description: `Стаж работы более 6 лет
-ботулинотерапия
-биоревитализация
-мезотерапия
-контурная пластика лица
-контурная пластика губ
-коллагеностимулирующие инъекционные процедуры (AеstheFill,GANAV,Miraline)
-SMAS-лифтинг на аппарате Ulthera(Альтера-терапия),на аппарате Ultraformer MPT
-Morpheus8-фракционная микроигольчатая RF-технология на платформе InMode
-фотоомоложение IPL Lumecca на платформе InMode
-PRP-терапия,плазмолифтинг(Кортексил)
-инъекционные методики омоложения "Radiesse"
-криолиполиз
-лазерная эпиляция`,
    education: [],
    img: "/images/doctors/Митяева Евгения Евгеньевна Врач косметолог-дерматолог.JPG",
  },
  {
    id: 14,
    name: "Нестерова Наталья Ивановна ",
    positions: ["Ведущий врач-косметолог,основатель клиники 'Космомед'"],
    description: `Ведущий врач-косметолог,основатель клиники "Космомед"

Стаж работы более 20 лет

-ботулинотерапия
-биоревитализация
-контурная пластика лица, губ
-коллагеностимулирующие инъекционные процедуры (AestheFill, Miraline)
-SMAS - лифтинг на аппарате Ulthera (Альтера-терапия), на аппарате Ultraformer MPT
-нитевой лифтинг Aptos
`,
    education: [
      '1992 г - Окончила Астраханский государственный медицинский институт по специальности "Педиатрия"',
      '1992-1993 г - прошла интернатуру по специальности "дерматовенерология"',
      "1993-2002 гг - работала до дерматовенерологом в Областной детской клинической больнице им. Н.Ф. Филатова.",
      '2011 г - прошла профессиональную переподготовку в межотраслевом региональном центре повышения квалификации ПГУ по программе "Косметология"',
      '2017 г - прошла профессиональную переподготовку в ФГБОУВО Сам ГМУ Минздрава России по программе "Косметология", получила диплом по специальности "Врач-косметолог"',
    ],
    img: "/images/doctors/Нестерова Наталья Ивановна Врач космометолог-дерматолог.jpg",
  },
  {
    id: 15,
    name: "Писарева Вера Константиновна",
    positions: ["Ведущий врач-косметолог,основатель клиники 'Космомед'"],
    description: `Ведущий врач-косметолог,основатель клиники "Космомед"

Стаж  работы более 20 лет

-ботулинотерапия
-биоревитализация
-контурная пластика лица,губ
-коллагеностимулирующие инъекционные процедуры (AestheFill, GANA, Miraline)
-SMAS - лифтинг на аппарате Ulthera (Альтера-терапия), на аппарате Ultraformer MPT
-нитевой лифтинг Aptos
`,
    education: [
      '1991 г - Окончила Целиноградский государственный медицинский институт по специальности "Педиатрия" в 1991 году',
      "2003 г - прошла профессиональную переподготовку в Самарском государственном медицинском университете по дерматовенерологии",
      '2008 г - прошла повышение квалификации в медицинском институте "РЕАВИЗ" по специальности "Терапевтическая косметология"',
      '2017 г - прошла профессиональную переподготовку в ФГБОУ ВО Сам ГМУ Минздрава России по программе "Косметология", получила диплом по специальности "Врач-косметолог"',
    ],
    img: "/images/doctors/Писарева Вера Константиновна Врач косметолог-дерматолог.jpg",
  },
  {
    id: 16,
    name: "Пискарёва Юлия Юрьевна",
    positions: ["Врач-косметолог-эстетист"],
    description:
      "Опыт работы в косметологии 18 лет. Ведение, лечение пациентов с акне, розацеа, гиперпигментацией,коррекция возрастных изменений кожи. Владею навыками, иньекционных методик (биоревитализация, био репарация), аппаратных (микротоки, радиоволновой  лифтинг, провожу химические пилинги различных производителей, механические чистки проблемных зон (лицо и тело), профессиональные уходы по лицу, владею навыками скульптурного и классического массажа, занимаюсь индивидуальным подбором косметики для домашнего ухода, постоянно обучаюсь, повышаю квалификацию",
    education: [""],
    img: "/images/doctors/Пискарёва Юлия Юрьевна Косметолог-эстетист.JPG",
  },
  {
    id: 17,
    name: "Сулейманова Елена Александровна",
    positions: ["Мастер по массажу, обертованиям"],
    description: `Стаж работы более 7 лет

-классический массаж (лечебный)
-детский массаж
-массаж шейно-воротниковой зоны
-омоложение лица без ботокса
-антицеллюлитный массаж
-липолитики
-обертывания
`,
    education: [
      "1999 г - Закончила Пензенский областной медицинский коллледж",
      '2007 г - Закончила Международный учебный центр "Формула красоты" г. Москва по специальности косметология',
    ],
    img: "/images/doctors/Сулейманова Елена Александровна массажист.JPG",
  },
  {
    id: 18,
    name: "Судакова Анастасия Владимировна",
    positions: ["Врач-косметолог дерматолог, трихолог"],
    description: `Стаж работы более 5 лет
-лечение заболеваний кожи и волос
-индивидуальный подбор косметики для домашнего ухода
-удаление новообразований кожи (родинок,папиллом,бородавок,кератоми тд)
-дерматоскопия
-лечение выпадения волос
-биопсия кожи
-коррекция рубцов
-ботулинотерапия
-биоревитализация
-мезотерапия
-контурная пластика лица
-контурнаяпластика губ
-коллагеностимулирующие инъекционные процедуры (AеstheFill,GANAV,Miraline)
-SMAS-лифтинг на аппарате Ulthera(Альтера-терапия),на аппарате Ultraformer  MPT
-Morpheus8-фракционная микроигольчатая RF-технология на платформе InMode
-фотоомоложение IPL Lumecca на платформе InMode
-PRP-терапия,плазмолифтинг(Кортексил)
`,
    education: [
      "2018 г - Окончила Саратовский государственный медицинский университет им. в.И. Разумовского",
      '2020 г - Окончила ординатуру по специальности "Дерматовенерология"',
      '2021 г - Окончила цикл переподготовки по специальности "Косметология"',
    ],
    img: "/images/doctors/Судакова Анастасия Владимировна Врач косметолог-дерматолог.JPG",
  },
  {
    id: 19,
    name: "Феклистова Евгения Сергеевна",
    positions: ["Врач-косметолог, трихолог-дерматолог"],
    description: `Стаж работы более 15 лет.

-ботулинотерапия
-биоревитализация,мезотерапия
-контурная пластика лица,губ
-коллагеностимулирующие иньекционные процедуры (AestheFill,GANA, Miraline)
-SMAS - лифтинг на аппарате Ulthera (Альтера-терапия), на аппарате Ultraformer MPT
-Morpheus8-фракционная микроигольчатая RF-технология на платформе InMode
-фотоомоложение IPL Lumecca на платформе InMode
-PRP-терапия,плазмолифтинг(Кортексил)
`,
    education: [
      "2004 г - Закончила Пензенский Медицинский Институт",
      "2006 г - Закончила клиническую интернатуру с отличием во Владивостоксом Государственном Медицинском Университете.",
    ],
    img: "/images/doctors/Феклистова Евгения Сергеевна Врач косметолог-дерматолог-трихолог.JPG",
  },
  {
    id: 20,
    name: "Хлебина Ольга Викторовна",
    positions: ["Кандидат медицинских наук, врач-гинеколог"],
    description: `Стаж работы более 20 лет

-диагностика и лечение заболеваний женской половой сферы,заболеваний шейки матки
-лечение нарушений менструального цикла
-подбор заместительной гормональной терапии методов контрацепции
-занимается подготовкой к планированию беременности.
-диагностика и лечение бесплодия (в том числе, психологических проблем)
`,
    education: [
      '2003 г - Окончила  Мордовский государственный университет им. Н.П. Огарева по специальности "Лечебное дело"',
      "2003-2005 гг - Прошла подготовку в клинической ординатуре по акушерству и гинекологии, работала над написанием кандидатской диссертации",
      "2005 г - Окончила Международные специализированные курсы по лазерной медицинской технике (г. Калуга)",
      '2006 г - Прошла тематическое усовершенствование по кольпоскопии при ГОУДПО "Пензенский институт усовершенствования врачей МЗ РФ"',
      "2011 г - Присуждена ученая степень кандидата медицинских наук",
    ],
    img: "/images/doctors/Хлебина Ольга Викторовна Врач гинеколог.JPG",
  },
  {
    id: 21,
    name: "Черкасова Людмила Викторовна",
    positions: ["Врач-косметолог, дерматолог"],
    description: `Стаж работы более 20 лет.

-ботулинотерапия
-биоревитализация
-мезотерапия
-контурная пластика лица
-контурная пластика губ
-коллагеностимулирующие инъекционные процедуры (AеstheFill,GANAV,Miraline)
-SMAS-лифтинг на аппарате Ulthera(Альтера-терапия),на аппарате Ultraformer  MPT
-Morpheus8-фракционная микроигольчатая RF-технология на платформе InMode
-фотоомоложение IPL Lumecca на платформе InMode
-лазерная эпиляция
-криолиполиз
`,
    education: [
      '2001 г - окончила Саратовский государственный медицинский институт по специальности "лечебное дело", квалификация "врач""',
      '2001–2003 гг – клиническая ординатура в Самарском государственном медицинском университете по специальности "Дерматовенерология"',
      '2015 г – профессиональная переподготовка по программе "Косметология"',
      '2020 г – повышение квалификации по программе "Косметология"',
      '2020 г – повышение квалификации по программе "Дерматовенерология"',
      '2015 г – обучение по работе на диодном лазере "MEDIOSTAR NEXt" г. Москва',
      '2015 г – сертификационный курс "Теория и практика применения препарата Диспорт (ботулинический токсин типа А) в эстетической медицине" с правом использования данного препарата в клинической практике – г. Москва Валлекс – М',
      "2016 г – теоретический и практический курс обучения по применению химических пилингов (различные протоколы), г. Москва Валлекс – М",
      "2016 г – Контурная пластика лица. Коррекция возрастных изменений с помощью дермальных филлеров по зонам: носогубная складка, межбровье, лоб. Гиперкоррекция. г. Москва, Первый профессиональный институт эстетики",
      "2016 г – Контурная пластика губ филлерами на основе гиалуроновой кислоты. г.Москва, Первый профессиональный институт эстетики",
      "2017 г – Контурная пластика. Объемное моделирование лица с помощью дермальных филлеров по зонам: периорбитальная и скуловая зона лица, височная и подчелюстная область. Техника классической канюльной коррекции. г. Москва, Первый профессиональный институт эстетики",
      "2017 г - Техники работы канюлями. г. Москва, Первый профессиональный институт эстетики",
      "2017 г – Полный курс мезотерапии.  г. Москва, Первый профессиональный институт эстетики",
      "2017 г – Международный конгресс по ANTI – AGE и эстетической медицине ENTERESTET 2017 – Кипр",
      '2017 г – Мезотерапия как метод лечения алопеции и восстановления качества волос".   г. Москва, Первый профессиональный институт эстетики',
      '2018 г – курс "Трихология"  г. Уфа, обучающий центр "Гелиосити"',
      '2018 г – "Авторский семинар. Коррекция возрастных изменений с помошью нейромезолифтинга" г. Москва, Первый профессиональный институт эстетики',
      '2019 г – Бьюти Практикум "Омоложение губ и периорбитальной области. Методика "Натура липс" г. Москва, в клинике к.м.н., дерматолога-косметолога Марии Ширшаковой',
      '2020 г – обучение на авторском курсе Е.А. Разумовской  "Функциональная ботулинотерапия в практике врача – косметолога. От коррекции морщин до управления эмоциональным статусом пациента" Уровень Advance в количестве 20 учебных часов. г. Самара',
      '2021 г – обучение на авторском курсе Е.А Разумовской "Филлерология. Руководство для практикующего врача – косметолога" г. Самара',
      "2022 г – Анатомический CADAVER курс TOX & FILL – Независимое расследование о ботулотоксинах и филлерах г. Москва, кафедра оперативной хирургии и топографической анатомии МГМУ им И.М. Сеченова",
      '2022 г – Авторский курс "Контурная пластика" в школе  Буяновой Е.Ю. г.Москва',
      "2023 г – Контурная пластика – объемное моделирование. г. Москва, Первый профессиональный институт эстетики",
    ],
    img: "/images/doctors/Черкасова Людмила Викторовна Врач косметолог-дерматовенеролог.JPG",
  },
  {
    id: 22,
    name: "Духовнова Кристина Михайловна ",
    positions: [
      `Врач-уролог,детский уролог-андролог,врач УЗД,врач-эндоскопист.`,
    ],
    description: `Стаж работы более 8 лет

⦁	Амбулаторная урология;
⦁	Интимная контурная пластика;
⦁	Не хирургическое лечение недержания мочи;
⦁	Не хирургическое лечение прежде временной эякуляции и увеличение полового члена;
⦁	Укрепление мышц тазового дна;
⦁	Плазмолифтинг интимной зоны;
⦁	Ультразвуковая диагностика.
`,
    education: [
      '2003 г - Окончила  Мордовский государственный университет им. Н.П. Огарева по специальности "Лечебное дело"',
      "2003-2005 гг - Прошла подготовку в клинической ординатуре по акушерству и гинекологии, работала над написанием кандидатской диссертации",
      "2005 г - Окончила Международные специализированные курсы по лазерной медицинской технике (г. Калуга)",
      '2006 г - Прошла тематическое усовершенствование по кольпоскопии при ГОУДПО "Пензенский институт усовершенствования врачей МЗ РФ"',
      "2011 г - Присуждена ученая степень кандидата медицинских наук",
    ],
    img: "/images/doctors/Духовнова Кристина Михайловна,врач-уролог, уролог-андролог, врач УЗД, врач-эндоскопист.jpg",
  },
  {
    id: 23,
    name: "Рылякина Татьяна Юрьевна",
    positions: ["Массажист"],
    description: `Стаж работы более 2 лет

-медицинский массаж (лечебный)
-антицеллюлитный массаж
-лимфодренажный массаж
-массаж лица(классический)
-аппаратный массаж
`,
    education: [
      '2003 г - Окончила  Мордовский государственный университет им. Н.П. Огарева по специальности "Лечебное дело"',
      "2003-2005 гг - Прошла подготовку в клинической ординатуре по акушерству и гинекологии, работала над написанием кандидатской диссертации",
      "2005 г - Окончила Международные специализированные курсы по лазерной медицинской технике (г. Калуга)",
      '2006 г - Прошла тематическое усовершенствование по кольпоскопии при ГОУДПО "Пензенский институт усовершенствования врачей МЗ РФ"',
      "2011 г - Присуждена ученая степень кандидата медицинских наук",
    ],
    img: "/images/doctors/Рылякина Татьяна Юрьевна массажист.JPG",
  },
];

export const services: any[] = [
  {
    id: 1,
    name: "Фотоомоложение на платформе Inmode, насадке Lumecca",
    img: "/images/services/46.png",
    description:
      "Процедура воздействия на кожу имульсов высокоинтенсивного света. В нашей клинике процедура фототерапии проводится на израильской платформе Inmode, насадке Lumecca. Воздействие во время процедуры проводится на хромофоры - оксигемоглобин и меланин, что позволяет эффективно работать с расширенными сосудами, застойными пятнами, а так же гиперпигментированными пятнами. В результате курса процедур происходит уменьшение выраженности пигмента, улучшение тона и цвета  лица, качества кожи и сокращение пор. ",
    list: [
      {
        name: "Фотоомоложение лицо",
        price1: 8000,
        price2: 8000,
        price3: "-",
      },
      {
        name: "Фотоомоложение лицо + шея",
        price1: 15000,
        price2: 15000,
        price3: "-",
      },
      {
        name: "Фотоомоложение щеки",
        price1: 4000,
        price2: 4000,
        price3: "-",
      },
      {
        name: "Фотоомоложение шея+декольте",
        price1: 14000,
        price2: 14000,
        price3: "-",
      },
      {
        name: "Фотоомоложение шея",
        price1: 8000,
        price2: 8000,
        price3: "-",
      },
      {
        name: "Фотоомоложение Лицо шея декольте",
        price1: 21000,
        price2: 21000,
        price3: "-",
      },
      {
        name: "Фотоомоложение Кисти рук",
        price1: 8000,
        price2: 8000,
        price3: "-",
      },
      {
        name: "Фотоомоложение Руки полностью",
        price1: 14000,
        price2: 14000,
        price3: "-",
      },
      {
        name: "Фотоомоложение 1 вспышка",
        price1: 1000,
        price2: 1000,
        price3: "-",
      },
      {
        name: "Фотоомоложение нос",
        price1: 2000,
        price2: 2000,
        price3: "-",
      },
      {
        name: "Сосудистый лазер 2кв.см",
        price1: "-",
        price2: 1000,
        price3: "-",
      },
      {
        name: "Игла индивидуальная",
        price1: "-",
        price2: 300,
        price3: "-",
      },
    ],
  },
  {
    id: 2,
    name: "Микроигольчатый РФ лифтинг на платформе Inmode, насадка Morfeus 8",
    img: "/images/services/4.png",
    description: `Процедура воздействия на глубокие слои кожи с помощью игл индивидуальной насадки, в которого усиливается синтез коллагена и эластина, уплотняется кожа, уменьшаются «брыли» и сглаживается носогубная складка. Процедура дает отличные результаты при работе в периорбитальной зоне, уплотняя кожу, уменьшая выраженность мимических морщин и темных кругов под глазами. Более того, микроигольчатыйrf-лифтинг активно используется в работе с качеством кожи в области тела, уменьшая дряблость кожи живота, бедер, внутренней поверхности плеч.В нашей клинике процедура проводится на израильской платформе Inmode, насадка Morfeus 8.
`,
    list: [
      {
        name: "Насадки для лица",
        price1: 12000,
        price2: 12000,
        price3: "-",
      },
      {
        name: "Насадки для тела",
        price1: 15000,
        price2: 15000,
        price3: "-",
      },
      {
        name: "Морфиус лицо полностью",
        price1: 20000,
        price2: 20000,
        price3: "-",
      },
      {
        name: "Морфиус Лицо без зоны глаз и лба",
        price1: 15000,
        price2: 15000,
        price3: "-",
      },
      {
        name: "Морфиус шея",
        price1: 15000,
        price2: 15000,
        price3: "-",
      },
      {
        name: "Морфиус глаза",
        price1: 10000,
        price2: 10000,
        price3: "-",
      },
      {
        name: "Морфиус декольте",
        price1: 15000,
        price2: 15000,
        price3: "-",
      },
      {
        name: "Морфиус руки",
        price1: 30000,
        price2: 30000,
        price3: "-",
      },
      {
        name: "Морфиус лицо полностью+шея",
        price1: 30000,
        price2: 30000,
        price3: "-",
      },
      {
        name: "Морфиус шея+лицо без глаз",
        price1: 25000,
        price2: 25000,
        price3: "-",
      },
      {
        name: "Морфиус шея+лицо полность+декольте",
        price1: 35000,
        price2: 35000,
        price3: "-",
      },
      {
        name: "Морфиус лицо без глаз+декольте",
        price1: 30000,
        price2: 30000,
        price3: "-",
      },
      {
        name: "Морфиус по телу (от 20000, в зависимости от зоны)",
        price1: 20000,
        price2: 20000,
        price3: "-",
      },
    ],
  },
  {
    id: 3,
    name: "Ультраформер МРТ",
    img: "/images/services/15.png",
    description: `Это 4-е поколение SMAS с максимальной глубиной проникновения в ткани лица и тела, имеющее доказанную эффективность: 8 независимых клинических испытаний и сотни тысяч проведенных  процедур. Режим MPT позволяет добиться не только мгновенного лифтинга и разглаживания морщин, но и сверхэффективного липолиза, способствуя гибели жировых клеток  и устранению избыточных жировых отложений в области лица и тела. Процедура позволяет эффективно бороться с мимическими морщинами и провисанием кожи, улучшает контур лица и качество кожи.`,
    list: [
      {
        name: "Ультраформер Ultraformer MPT - 1 линия",
        price1: 80,
        price2: "-",
        price3: "-",
      },
      {
        name: "Ultra Booster (максимальная проработка рельефа) - 1 линия",
        price1: 80,
        price2: "-",
        price3: "-",
      },
      {
        name: "Маска+лифтинг CLUEDERM LIFTING(для лица и подбородка)",
        price1: 700,
        price2: "-",
        price3: "-",
      },
    ],
  },
  {
    id: 4,
    name: "Альтера терапия",
    img: "/images/services/28.png",
    description: `Процедура с технологией сфокусированного ультразвука для лифтинга кожи, без повреждения окружающих тканей. Ультразвуковые волны воздействуют на верхний и глубокие слои кожи, прорабатывая мышечный слой, который выполняет роль каркаса лица. На терапии тщательно прорабатывается SMAS слой, мышцы и дерма, это стимулирует заново выстраивать коллагеновую матрицу. 
Сфокусированный луч ультразвука, перемещает глубинные слои: мышцы и фасции лица, моделируя контур. Специалист контролирует глубину, активность ультразвукового воздействия, сканируя структуры тканей ультразвуком, что обеспечивает максимальный эффект. Так достигается эффект лифтинга. Процедура позволяет бороться с опущением мышечных тканей (птозом) и является альтернативой SMAS-подтяжке.
`,
    list: [
      // {
      //   name: "Нития",
      //   price1: 15000,
      //   price2: 15000,
      //   price3: "-",
      // },
      // {
      //   name: "Коллост микро / Россия",
      //   price1: 16000,
      //   price2: 16000,
      //   price3: 16000,
      // },
      // {
      //   name: "Проба Коллост",
      //   price1: 1500,
      //   price2: 1500,
      //   price3: 1500,
      // },
      // {
      //   name: "Коллост 7% / Россия",
      //   price1: 11500,
      //   price2: "-",
      //   price3: "-",
      // },
      // {
      //   name: "Инфини с коллагеном аминокислотами и витамином С 1 упа. * 5флак * 10 мл / Италия (цена за 5мл)",
      //   price1: 6000,
      //   price2: 6000,
      //   price3: 6000,
      // },
    ],
  },
  {
    id: 5,
    name: "Криолиполиз аппарате CLATUU",
    img: "/images/services/40.png",
    description: `Криолиполиз на аппарате CLATUU — уникальный безоперационный метод коррекции фигуры, не имеющий равных по своей безопасности и эффективности. Во время процедуры с помощью контролируемого охлаждения происходит разрушение жировых клеток в необходимой зоне, которые потом будут выводиться из организма естественным путем с током лимфы. За счет технологии кругового охлаждения 360° уже за один сеанс CLATUU можно удалить до 20% подкожного жира.`,
    list: [
      {
        name: "Криолиполиз 1 банка",
        price1: "-",
        price2: 8200,
        price3: "-",
      },
      {
        name: "Криолиполиз 2 банки одновременно (на 1 банку -10%)",
        price1: "-",
        price2: 15580,
        price3: "-",
      },
      {
        name: "Криолиполиз 2 банки поочередно (на 1 банку -5%)",
        price1: "-",
        price2: 15990,
        price3: "-",
      },
    ],
  },
  {
    id: 6,
    name: "BODY FX",
    img: "/images/services/50.png",
    description: `это процедура не хирургического липолиза для коррекции жировых отложений. В течении одной процедуры происходит одновременное разрушение жировых клеток, подтяжка кожи и улучшение лимфооттока при помощи воздействия вакуума и радиочастотной энергии. Разрушенные жировые выводятся естественным путем через лимфосистему. 
В нашей клинике процедура проводится на израильской платформе Inmode.
`,
    list: [
      {
        name: "Боди FX (от 3000, в зависимости от зоны",
        price1: 3000,
        price2: "-",
        price3: "-",
      },
    ],
  },
  {
    id: 7,
    name: "Контурная пластика",
    img: "/images/services/51.png",
    description: `Инъекционный способ восполнения необходимого объёма в нужной зоне, заполнения морщин, увеличения объема губ, корректировки асимметрии и изменения формы (например носа, губ, подбородка). С возрастом черты лица могут сглаживаться и терять объем, быть менее отчётливыми. Контурная пластика позволяет сделать их более выразительными и рельефными, а параметры лица в целом более гармоничными и индивидуальными.`,
    list: [
      {
        name: "Арт филлер Юниверсал/Бельгия",
        price1: 16500,
        price2: 16500,
        price3: 16500,
      },
      {
        name: "Арт филлер Волюм/Бельгия",
        price1: 17000,
        price2: 17000,
        price3: 17000,
      },
      {
        name: "Арт филлер липс/Бельгия",
        price1: 16500,
        price2: 16500,
        price3: 16500,
      },
      {
        name: "Арт филлер файн лайн/Бельгия",
        price1: 15000,
        price2: 15000,
        price3: 15000,
      },
      {
        name: "Белотеро софт/Швейцария",
        price1: 15500,
        price2: 15500,
        price3: 15500,
      },
      {
        name: "Белотеро интенс/Швейцария",
        price1: 16500,
        price2: 16500,
        price3: 16500,
      },
      {
        name: "Белотеро интенс с лидоканном/Швейцария",
        price1: 16500,
        price2: 16500,
        price3: 16500,
      },
      {
        name: "Белотеро волиум интенс/Швейцария",
        price1: 16500,
        price2: 16500,
        price3: 16500,
      },
      {
        name: "Белотеро шейп липс/Швейцария",
        price1: 13500,
        price2: 13500,
        price3: 13500,
      },
      {
        name: "Белотеро липс/контур/Швейцария",
        price1: "-",
        price2: 12000,
        price3: "-",
      },
      {
        name: "Белотеро баланс/Швейцария",
        price1: 15500,
        price2: 15500,
        price3: 15500,
      },
      {
        name: "Белотеро баланс с лидоканном/Швейцария",
        price1: 15500,
        price2: 15500,
        price3: 15500,
      },
      {
        name: "Белотеро гидро/Швейцария",
        price1: 12000,
        price2: "-",
        price3: "-",
      },
      {
        name: "Стилаж M с лидо/Франция",
        price1: 14000,
        price2: 14000,
        price3: 14000,
      },
      {
        name: "Стилаж Липс с лидокаином/Франция",
        price1: 15000,
        price2: 15000,
        price3: "-",
      },
      {
        name: "Стилаж L/Франция",
        price1: 15000,
        price2: 15000,
        price3: "-",
      },
      {
        name: "Стилаж XL/Франция",
        price1: 16000,
        price2: 16000,
        price3: "-",
      },
      {
        name: "Ньювиа интенс/Италия",
        price1: 15000,
        price2: 15000,
        price3: "-",
      },
      {
        name: "Ньювиа LV/Италия",
        price1: 15000,
        price2: 15000,
        price3: "-",
      },
      {
        name: "Ньювиа Реолоджи",
        price1: 15000,
        price2: 15000,
        price3: "-",
      },
      {
        name: "Ньювиа флакс/Италия",
        price1: 15000,
        price2: 15000,
        price3: "-",
      },
      {
        name: "Ньювиа липс/Италия",
        price1: 15000,
        price2: 15000,
        price3: "-",
      },
      {
        name: "Ньювиа стимулэйт/Италия",
        price1: 16000,
        price2: 16000,
        price3: 16000,
      },
      {
        name: "Миралайн Sab-O",
        price1: 10500,
        price2: 10500,
        price3: 10500,
      },
      {
        name: "Миралайн Deep Plus",
        price1: 10500,
        price2: 10500,
        price3: 10500,
      },
      {
        name: "Миралайн Fine Plus",
        price1: 10500,
        price2: 10500,
        price3: 10500,
      },
      {
        name: "Филлер Филлекс Fine Lips/Великобретания,Корея",
        price1: 11000,
        price2: 11000,
        price3: 11000,
      },
      {
        name: "Филлер Филлекс Deep Lips/Великобретания,Корея",
        price1: 11000,
        price2: 11000,
        price3: 11000,
      },
      {
        name: "Филлер Филлекс Deep Plus1.1мл/Великобретания,Корея",
        price1: 11000,
        price2: 11000,
        price3: 11000,
      },
      {
        name: "Филлер ФиллексSUB-Q Plus1.1мл/Великобретания,Корея",
        price1: 11000,
        price2: 11000,
        price3: 11000,
      },
      {
        name: "Филлер Новокутан медиум,волиум,лайт/Франция,Корея",
        price1: 13000,
        price2: 13000,
        price3: 13000,
      },
      {
        name: "Кьютифил дип/Корея",
        price1: 10000,
        price2: 10000,
        price3: 10000,
      },
      {
        name: "Кьютифилл Саб кью/Корея",
        price1: 10000,
        price2: 10000,
        price3: 10000,
      },
      {
        name: "Этермис 4",
        price1: 13000,
        price2: 13000,
        price3: 13000,
      },
      {
        name: "Цитосиаль Дип Вринкс 1.1 мл./Франция",
        price1: 12000,
        price2: 12000,
        price3: 12000,
      },
      {
        name: "Принцесс волиум/Австрия",
        price1: 10000,
        price2: 10000,
        price3: 10000,
      },
    ],
  },
  {
    id: 8,
    name: "Коллагеностимуляция",
    img: "/images/services/52.png",
    description: `Это инъекционная процедура введения препаратов, способствующих выработке организмом собственного коллагена.
Со временем кожа становится менее упругой и выглядит старее. Это происходит потому, что замедляются процессы синтеза коллагена в организме. Чтобы вернуть коже прежний здоровый и подтянутый вид необходимо вводить коллаген извне.
Для коллагеностимуляции используются препараты, содержащие гидрооксипатит кальция, полимолочную кислоту или натуральный коллаген. Процедура направлена на уменьшение признаков старения, возвращение упругости лица, избавление от морщин, рубцов и постакне`,
    list: [
      {
        name: "Фэйстейм 3мл/Корея",
        price1: 31000,
        price2: "-",
        price3: "-",
      },
      {
        name: "Радиес 3,0мл/США",
        price1: 36500,
        price2: 36500,
        price3: "-",
      },
      {
        name: "Радиес 1,5мл/США",
        price1: 21500,
        price2: 21500,
        price3: "-",
      },
      {
        name: "Нития",
        price1: 15000,
        price2: 15000,
        price3: "-",
      },
      {
        name: "Коллост микро / Россия",
        price1: 16000,
        price2: 16000,
        price3: 16000,
      },
      {
        name: "Курс (3 процедуры) скидка 1500руб.",
        price1: 37500,
        price2: 37500,
        price3: 37500,
      },
      {
        name: "Проба Коллост",
        price1: 1500,
        price2: 1500,
        price3: 1500,
      },
      {
        name: "Коллост 7% / Россия",
        price1: 11500,
        price2: "-",
        price3: "-",
      },
    ],
  },
  {
    id: 9,
    name: "Мезотерапия ",
    img: "/images/services/53.png",
    description: `Мезотерапия - это метод введения активных коктейлей в глубокие слои дермы с целью улучшения качества кожи, ее внешнего вида, тонуса и тургора, а также для решения отдельных косметологических проблем.
Мезотерапевтические процедуры направлены на доставку витаминов, минералов, аминокислот или иных полезных веществ в дерму. Глубина введения препаратов достигает трех миллиметров. Смесь используемых веществ именуется лечебными коктейлями или сыворотками.
Состав микса определяется косметологом на основании текущего состояния кожных покровов пациентов и терапевтических целей инъекций.
`,
    list: [
      {
        name: "NCTF НА 3мл./Франция",
        price1: 8000,
        price2: 8000,
        price3: 8000,
      },
      {
        name: "NCTF НА 1,5мл./Франция",
        price1: 4000,
        price2: 4000,
        price3: 4000,
      },
      {
        name: "Мезошайн 5мл.",
        price1: 5000,
        price2: 5000,
        price3: 5000,
      },
      {
        name: "Мезошайн 2.5мл.",
        price1: 3500,
        price2: 3500,
        price3: 3500,
      },
      {
        name: "Мезошайн 1.5мл.",
        price1: 2500,
        price2: 2500,
        price3: 2500,
      },
    ],
  },
  {
    id: 10,
    name: "Процедура биоревитализации",
    img: "/images/services/54.png",
    description: `Процедура биоревитализациив ведение в кожу препаратов гиалуроновой кислоты - обеспечивает глубокое увлажнение кожи, стимулирует выработку коллагена и эластина, восстанавливает межклеточный матрикс, дает anti-age эффект, стирая с лица признаки преждевременного старения.
`,
    list: [
      {
        name: "Ньювиа гидро делюкс 2,5 мл 2 шприц./Италия",
        price1: 13000,
        price2: 13000,
        price3: 13000,
      },
      {
        name: "Ялу про 1%/Италия",
        price1: 9000,
        price2: 9000,
        price3: 9000,
      },
      {
        name: "Ялу про HMW 2%",
        price1: 12000,
        price2: 12000,
        price3: 12000,
      },
      {
        name: "Новокутан Sbio/Франция, Корея",
        price1: 13000,
        price2: 13000,
        price3: 13000,
      },
      {
        name: "Новокутан Ybio/Франция, Корея",
        price1: 13000,
        price2: 13000,
        price3: 13000,
      },
      {
        name: "Философия лицо 1,5мл./Россия",
        price1: 5000,
        price2: 5000,
        price3: 5000,
      },
      {
        name: "Философия губы 1мл.",
        price1: 2000,
        price2: 2000,
        price3: 2000,
      },
      {
        name: "Философия лицо+шея+декольте 2мл.",
        price1: 6000,
        price2: 6000,
        price3: 6000,
      },
      {
        name: "Миралайн HYDRO",
        price1: 10000,
        price2: 10000,
        price3: 10000,
      },
      {
        name: "Аква Ц/Корея",
        price1: 6000,
        price2: 6000,
        price3: 6000,
      },
      {
        name: "Плинест/Италия",
        price1: 11000,
        price2: 11000,
        price3: 11000,
      },
      {
        name: "Плинест фас./Италия",
        price1: 11000,
        price2: 11000,
        price3: 11000,
      },
      {
        name: "Скинко E/Франция 5мл.",
        price1: 8500,
        price2: 8500,
        price3: 8500,
      },
      {
        name: "Скинко E/Франция 2,5мл.",
        price1: 6000,
        price2: 6000,
        price3: 6000,
      },
      {
        name: "Скинко E/Франция 1,5мл.",
        price1: 3500,
        price2: 3500,
        price3: 3500,
      },
      {
        name: "Профайло/Италия",
        price1: 17000,
        price2: 17000,
        price3: 17000,
      },
      {
        name: "RRS HA Long Lasting 3мл./Испания",
        price1: 16000,
        price2: 16000,
        price3: 16000,
      },
      {
        name: "Вискодерм трио 1,6%/Франция",
        price1: 10000,
        price2: 13500,
        price3: 13500,
      },
      {
        name: "Мезовартон/США, Корея",
        price1: 13500,
        price2: 13500,
        price3: 13500,
      },
      // {
      //   name: "Ксантин/Франция",
      //   price1: 13500,
      //   price2: 13500,
      //   price3: 13500,
      // },
      {
        name: "Мезоай/Франция скальп",
        price1: 13500,
        price2: 13500,
        price3: 13500,
      },
      {
        name: "Мезоксантин/Франция",
        price1: 13500,
        price2: 13500,
        price3: 13500,
      },
      {
        name: "Цитакея S Line 3мл/Франция",
        price1: 15000,
        price2: 15000,
        price3: 15000,
      },
      {
        name: "Цитакея 2,5мл. 532/Франция",
        price1: 5500,
        price2: 5500,
        price3: 5500,
      },
      {
        name: "Цитакея 5мл. 532/Франция",
        price1: 10000,
        price2: 10000,
        price3: 10000,
      },
      {
        name: "Цитакея 2,5мл. 715/Франция",
        price1: 6000,
        price2: 6000,
        price3: 6000,
      },
      {
        name: "Цитакея 5мл. 715/Франция",
        price1: 11000,
        price2: 11000,
        price3: 11000,
      },
      {
        name: "Цитакея 3мл. 502/Франция",
        price1: 8000,
        price2: 8000,
        price3: 8000,
      },
      {
        name: "Цитакея 2,5мл. 502/Франция",
        price1: 4000,
        price2: 4000,
        price3: 4000,
      },
      // {
      //   name: "Инфинс с коллагеном аминокислотами и витамином C 1упа. *5флак*10 мл/Италия 5мл.",
      //   price1: 6000,
      //   price2: 6000,
      //   price3: 6000,
      // },
      // {
      //   name: "Инфинс с коллагеном аминокислотами и витамином C 1упа. *5флак*10 мл/Италия 10 мл.",
      //   price1: 10000,
      //   price2: 10000,
      //   price3: 10000,
      // },
      {
        name: "Гиалрепайер-08 1,5мл./Россия",
        price1: "-",
        price2: 10000,
        price3: "-",
      },
      {
        name: "AGT bio лифтинг и регенерация 2,5мл./Россия",
        price1: 5000,
        price2: 5000,
        price3: 5000,
      },
      {
        name: "AGT bio лифтинг и регенерация 5мл./Россия",
        price1: 7000,
        price2: 7000,
        price3: 7000,
      },
      {
        name: "AGT bio молодость и сияние 2,5мл./Россия",
        price1: 5000,
        price2: 5000,
        price3: 5000,
      },
      {
        name: "AGT bio молодость и сияние 5мл./Россия",
        price1: 7000,
        price2: 7000,
        price3: 7000,
      },
      {
        name: "AGT bio здоровье и чистая кожа 2,5мл./Россия",
        price1: 5000,
        price2: 5000,
        price3: 5000,
      },
      {
        name: "AGT bio здоровье и чистая кожа 5мл./Россия",
        price1: 7000,
        price2: 7000,
        price3: 7000,
      },
      {
        name: "AGT Bio Colegium 5мл./Россия",
        price1: 8000,
        price2: 8000,
        price3: 8000,
      },
      {
        name: "AGT Bio Colegium 2,5мл./Россия",
        price1: 5000,
        price2: 5000,
        price3: 5000,
      },
      {
        name: "Bio Prospect PDRN 5мл./Россия",
        price1: "-",
        price2: 10000,
        price3: 10000,
      },
      {
        name: "Bio Prospect PDRN 2,5мл./Россия",
        price1: "-",
        price2: 6000,
        price3: 6000,
      },
      {
        name: "Bio Prospect DMAE 5мл./Россия",
        price1: "-",
        price2: 8000,
        price3: 8000,
      },
      {
        name: "Bio Prospect DMAE 2,5мл./Россия",
        price1: "-",
        price2: 6000,
        price3: 6000,
      },
      {
        name: "Bio Prospect TREHALOSE 5мл./Россия",
        price1: "-",
        price2: 8000,
        price3: 8000,
      },
      {
        name: "Bio Prospect TREHALOSE 2,5мл./Россия",
        price1: "-",
        price2: 6000,
        price3: 6000,
      },
      {
        name: "Bio Prospect AMBER EYES 5мл./Россия",
        price1: "-",
        price2: 8000,
        price3: 8000,
      },
      {
        name: "Bio Prospect AMBER EYES 2,5мл./Россия",
        price1: "-",
        price2: 6000,
        price3: 6000,
      },
      {
        name: "Bio Prospect AMBER EYES 1,5мл./Россия",
        price1: "-",
        price2: 3000,
        price3: 3000,
      },

      {
        name: "Bio Prospect MANNITOL 5мл./Россия",
        price1: "-",
        price2: 7500,
        price3: 7500,
      },
      {
        name: "Bio Prospect MANNITOL 2,5мл./Россия",
        price1: "-",
        price2: 5000,
        price3: 5000,
      },
      {
        name: "Bio Prospect ZINC ULTRA 5мл./Россия",
        price1: "-",
        price2: 7000,
        price3: 7000,
      },
      {
        name: "Bio Prospect ZINC ULTRA 2,5мл./Россия",
        price1: "-",
        price2: 5000,
        price3: 5000,
      },
      {
        name: "Bio Prospect SUCCINATE 5мл./Россия",
        price1: "-",
        price2: 7500,
        price3: 7500,
      },
      {
        name: "Bio Prospect SUCCINATE 2,5мл./Россия",
        price1: "-",
        price2: 5000,
        price3: 5000,
      },
      {
        name: "Сферогель медиум 0,5 мл./Россия",
        price1: 8000,
        price2: 8000,
        price3: 8000,
      },
      {
        name: "Сферогель медиум 1 мл./Россия",
        price1: 14000,
        price2: 14000,
        price3: 14000,
      },
      {
        name: "Сферогель лонг файн 0,5 мл./Россия",
        price1: 14000,
        price2: 14000,
        price3: 14000,
      },
      {
        name: "Сферогель лонг дванс 0,5 мл./Россия",
        price1: 12000,
        price2: 12000,
        price3: 12000,
      },
      {
        name: "Курасен",
        price1: 6500,
        price2: "-",
        price3: "-",
      },
      {
        name: "Реви ай/Россия",
        price1: 9000,
        price2: 9000,
        price3: "-",
      },
      {
        name: "Реви силк/Россия",
        price1: 10000,
        price2: 10000,
        price3: "-",
      },
      {
        name: "Комфорт 2,25мл./Россия",
        price1: 8000,
        price2: 8000,
        price3: 8000,
      },
      {
        name: "Принцесс рич/Австрия",
        price1: 7000,
        price2: 7000,
        price3: 7000,
      },
    ],
  },
  {
    id: 11,
    name: "Плазмотерапия",
    img: "/images/services/55.png",
    description: `Инновационный метод лечения, когда используют собственную кровь пациента, чтобы ускорить процесс заживления тканей и стимулировать регенерацию клеток. В косметологии плазмотерапия часто используется для улучшения состояния кожи, цвета лица, уменьшения мелких морщин. В трихологии активно применяется для стимуляции роста волос и лечении перхоти и себореи.`,
    list: [
      {
        name: "Кортексил PRP лицо",
        price1: 6000,
        price2: 6000,
        price3: "-",
      },
      {
        name: "Плазмалифтинг 1 пробирка",
        price1: 3500,
        price2: 3500,
        price3: "-",
      },
      {
        name: "Плазмалифтинг 2 пробирка",
        price1: 6000,
        price2: 6000,
        price3: "-",
      },
      {
        name: "Плазмалифтинг 3 пробирка",
        price1: 8500,
        price2: 8500,
        price3: "-",
      },
      // {
      //   name: "МРХ 5 мл / Швейцария 1 фл.",
      //   price1: 2000,
      //   price2: 2000,
      //   price3: 2000,
      // },
      // {
      //   name: "МРХ 5 мл / Швейцария 2 фл.",
      //   price1: 3500,
      //   price2: 3500,
      //   price3: 3500,
      // },
      // {
      //   name: "МРХ 5 мл / Швейцария 3 фл.",
      //   price1: 4000,
      //   price2: 4000,
      //   price3: 4000,
      // },
      // {
      //   name: "Сыворотка P.I. 2 мл. 1 фл.",
      //   price1: 4000,
      //   price2: 4000,
      //   price3: 4000,
      // },
      // {
      //   name: "Сыворотка P.I. 2 мл. 2 фл.",
      //   price1: 7000,
      //   price2: 7000,
      //   price3: 7000,
      // },
      // {
      //   name: "Сыворотка P.I. 2 мл. 3 фл.",
      //   price1: 9000,
      //   price2: 9000,
      //   price3: 9000,
      // },
      // {
      //   name: "Целюформ 10 мл.",
      //   price1: 4000,
      //   price2: "-",
      //   price3: "-",
      // },
      // {
      //   name: "Фосфадицилхолил 5 мл. / Испания",
      //   price1: 2000,
      //   price2: 2000,
      //   price3: 2000,
      // },
    ],
  },
  {
    id: 12,
    name: "Ботулинотерапия",
    img: "/images/services/56.png",
    description: `Процедура инъекционного введения ботулинического токсина типа А с целью расслабления мышц и разглаживания морщин. Процедура используется для коррекции морщин в зоне верхней трети лица, нижней трети лица и шеи, что обеспечивает лифтинг-тканей, четкий овал лица и коррекцию морщин. 
Кроме этого, процедура позволяет решить проблему гипергидроза (повышенного потоотделения) в области подмышек, кистей и стоп. `,
    list: [
      {
        name: "Ксеомин 50/Америка",
        price1: 250,
        price2: "-",
        price3: "-",
      },
      {
        name: "Ксеомин 100/Америка",
        price1: 250,
        price2: "-",
        price3: "-",
      },
      {
        name: "Диспорт/Франция",
        price1: 110,
        price2: 90,
        price3: 90,
      },
      {
        name: "Диспорт (подмышки, ладони, стопы)",
        price1: 90,
        price2: 90,
        price3: 90,
      },
      {
        name: "Миотоск/Россия",
        price1: 250,
        price2: 200,
        price3: 200,
      },
      {
        name: "Миотоск (подмышки, ладони, стопы)",
        price1: 200,
        price2: 200,
        price3: 200,
      },
    ],
  },
  {
    id: 13,
    name: "Липолитики",
    img: "/images/services/0.png",
    description: `Это процедура введения препаратов для коррекции тела, которые вводятся под кожу инъекционно и позволяют убрать отложения жира на животе, бедрах, лице, шее, руках, в зоне декольте. Методика призвана не только избавить от жировых отложений, но и улучшить состояние кожи, восстановить обмен веществ в тканях.`,
    list: [
      {
        name: "Лайт фит 2 мл 1фл.",
        price1: 4000,
        price2: 4000,
        price3: 4000,
      },
      {
        name: "2 фл.",
        price1: 7000,
        price2: 7000,
        price3: 7000,
      },
      {
        name: "3 фл.",
        price1: 9000,
        price2: 9000,
        price3: 9000,
      },
      {
        name: "4 фл.",
        price1: 11500,
        price2: 11500,
        price3: 11500,
      },
      {
        name: "MPX 5 мл /Швейцария 1фл.",
        price1: 2000,
        price2: 2000,
        price3: 2000,
      },
      {
        name: "2 фл.",
        price1: 3500,
        price2: 3500,
        price3: 3500,
      },
      {
        name: "3 фл.",
        price1: 4000,
        price2: 4000,
        price3: 4000,
      },
      {
        name: "Сыворотка P.I. 2 мл. 1фл.",
        price1: 4000,
        price2: 4000,
        price3: 4000,
      },
      {
        name: "Сыворотка P.I. 2 мл. 2 фл.",
        price1: 7000,
        price2: 7000,
        price3: 7000,
      },
      {
        name: "Сыворотка P.I. 2 мл. 3 фл.",
        price1: 9000,
        price2: 9000,
        price3: 9000,
      },
      {
        name: "Сыворотка P.I. 2 мл. 4 фл.",
        price1: 11500,
        price2: 11500,
        price3: 11500,
      },
      {
        name: "Целюформ 10мл.",
        price1: 4000,
        price2: "-",
        price3: "-",
      },
      {
        name: "Фосфатидилхолин 5мл. /Испания",
        price1: 2000,
        price2: 2000,
        price3: 2000,
      },
      // {
      //   name: "Фотоомоложение лицо + шея",
      //   price1: 15000,
      //   price2: 15000,
      //   price3: '-',
      // },
      // {
      //   name: "Фотоомоложение щеки",
      //   price1: 4000,
      //   price2: 4000,
      //   price3: "-",
      // },
      // {
      //   name: "Фотоомоложение шея+декольте",
      //   price1: 14000,
      //   price2: 14000,
      //   price3: "-",
      // },
      // {
      //   name: "Фотоомоложение шея",
      //   price1: 8000,
      //   price2: 8000,
      //   price3: "-",
      // },
      // {
      //   name: "Фотоомоложение Лицо шея декольте",
      //   price1: 21000,
      //   price2: 21000,
      //   price3: "-",
      // },
      // {
      //   name: "Фотоомоложение Кисти рук",
      //   price1: 8000,
      //   price2: 8000,
      //   price3: "-",
      // },
      // {
      //   name: "Фотоомоложение Руки полностью",
      //   price1: 14000,
      //   price2: 14000,
      //   price3: "-",
      // },
      // {
      //   name: "Фотоомоложение 1 вспышка",
      //   price1: 1000,
      //   price2: 1000,
      //   price3: "-",
      // },
      // {
      //   name: "Фотоомоложение нос",
      //   price1: 2000,
      //   price2: 2000,
      //   price3: "-",
      // },
      // {
      //   name: "Сосудистый лазер 2кв.см",
      //   price1: '-',
      //   price2: 1000,
      //   price3: "-",
      // },
      // {
      //   name: "Игла индивидуальная",
      //   price1: '-',
      //   price2: 300,
      //   price3: "-",
      // },
    ],
  },
  {
    id: 14,
    name: "Нитевой лифтинг",
    img: "/images/services/1.png",
    description: `Это косметологическая процедура, во время которой под кожу устанавливаются нити, сделанные из биологически совместимого материала и обеспечивают подтяжку тканей.
Принцип действия нитей  заключается в формировании каркаса, обеспечивающего натяжение кожных покровов и мягких тканей. Нити не только дают армирующий эффект, но и обеспечивают его сохранение на длительное время. Они стимулируют выработку коллагена, который отвечает за упругость кожи, повышает ее плотность и эластичность. Со временем каркас обрастает соединительной тканью и сохраняет свою форму даже после рассасывания нитей. Процедура дает возможность избавиться от дряблости кожных покровов, признаков старения тканей, видимых возрастных изменений.
`,
    list: [
      {
        name: "Нити Аптос Треды 2G софт (на иглах) / Россия",
        price1: 35500,
        price2: 35500,
        price3: "-",
      },
      {
        name: "Нити Аптос Треды блант (на канюлях) / Россия",
        price1: 35500,
        price2: 35500,
        price3: "-",
      },
      {
        name: "Нити Аптос ЛЛ спринг блант LIGHT LIFT / Россия",
        price1: 25500,
        price2: 25500,
        price3: "-",
      },
      {
        name: "Нити Аптос ЛЛ Нидл2Gсофт LIGHT LIFT / Россия",
        price1: 38500,
        price2: 38500,
        price3: "-",
      },
      {
        name: "Нити Аптос Экселанс Элеганс / Россия",
        price1: 24500,
        price2: 24500,
        price3: "-",
      },
      {
        name: "Нити Аптос Экселанс Визаж / Россия",
        price1: 38000,
        price2: 38000,
        price3: "-",
      },
      {
        name: "Нити Аптос Экселанс визаж НА / Россия",
        price1: 42000,
        price2: 42000,
        price3: "-",
      },
      {
        name: "Нити Аптос Экселанс визаж НА NAMICA / Россия",
        price1: 45000,
        price2: 45000,
        price3: "-",
      },
      {
        name: "Нити Аптос Экселанс Визаж Софт / Россия",
        price1: 35000,
        price2: 36000,
        price3: "-",
      },
      {
        name: "Маска Аптос",
        price1: 700,
        price2: 700,
        price3: "-",
      },
      {
        name: "Охлаждающий пакет",
        price1: 300,
        price2: 300,
        price3: "-",
      },
    ],
  },
  {
    id: 15,
    name: "Консультация",
    img: "/images/services/2.png",
    description: `Консультация дерматолога включает в себя осмотр кожи и ногтевых пластин, проведение лечения острых и хронических кожных заболеваний. `,
    list: [
      {
        name: "Консультация",
        price1: 1800,
        price2: 1800,
        price3: 1800,
      },
      {
        name: "Консультация повторная",
        price1: 1500,
        price2: 1500,
        price3: 1500,
      },
      {
        name: "Консультация по удалению",
        price1: 700,
        price2: 700,
        price3: 700,
      },
    ],
  },
  {
    id: 16,
    name: "Лечение акне, розацеа",
    img: "/images/services/3.png",
    description: `В нашей клинике проводится комплексное лечение  хронических кожных заболеваний, таких как акне и розацеа. Назначение терапии, подбор ухода, а так же косметологических процедур с учетом особенностей кожи. Диагностика кожи проводится с помощью аппарата Aramo Smart Wizard ASW-300
 `,
    list: [
      {
        name: "Консультация",
        price1: 1800,
        price2: 1800,
        price3: 1800,
      },
      {
        name: "Консультация повторная",
        price1: 1500,
        price2: 1500,
        price3: 1500,
      },
      {
        name: "Консультация по удалению",
        price1: 700,
        price2: 700,
        price3: 700,
      },
      // {
      //   name: "1 насадка",
      //   price1: "-",
      //   price2: "2000 Акция! 1000",
      //   price3: "-",
      // },
      // {
      //   name: "2 насадки",
      //   price1: "-",
      //   price2: "2500 Акция! 1500",
      //   price3: "-",
      // },
      // {
      //   name: "3 насадки",
      //   price1: "-",
      //   price2: "3000 Акция! 2000",
      //   price3: "-",
      // },
      // {
      //   name: "4 насадки",
      //   price1: "-",
      //   price2: "3000 Акция! 2000",
      //   price3: "-",
      // },
      // {
      //   name: "1 насадка / Курс от 5 процедур скидка 30%",
      //   price1: "-",
      //   price2: 7500,
      //   price3: "-",
      // },
      // {
      //   name: "2 насадки / Курс от 5 процедур скидка 30%",
      //   price1: "-",
      //   price2: 10500,
      //   price3: "-",
      // },
      // {
      //   name: "3 насадки / Курс от 5 процедур скидка 30%",
      //   price1: "-",
      //   price2: 13500,
      //   price3: "-",
      // },
      // {
      //   name: "4 насадки / Курс от 5 процедур скидка 30%",
      //   price1: "",
      //   price2: 15000,
      //   price3: "-",
      // },
    ],
  },
  {
    id: 17,
    name: "Лечение пигментации",
    img: "/images/services/5.png",
    description: `Комплексное лечение пигментации с учетом соматической патологии, коррекции ухода и составления протокола процедур. Диагностика кожи проводится с помощью аппарата Aramo Smart Wizard ASW-300

`,
    list: [
      {
        name: "Консультация",
        price1: 1800,
        price2: 1800,
        price3: 1800,
      },
      {
        name: "Консультация повторная",
        price1: 1500,
        price2: 1500,
        price3: 1500,
      },
      {
        name: "Консультация по удалению",
        price1: 700,
        price2: 700,
        price3: 700,
      },
    ],
  },
  {
    id: 18,
    name: "Лечение рубцов",
    img: "/images/services/6.png",
    description: `В нашей клинике осуществляется инъекционное и наружнее лечение гипертрофических и келоидных рубцов, а так же коррекция атрофических рубцов, в том числе постакне.`,
    list: [
      {
        name: "Консультация",
        price1: 1800,
        price2: 1800,
        price3: 1800,
      },
      {
        name: "Консультация повторная",
        price1: 1500,
        price2: 1500,
        price3: 1500,
      },
      {
        name: "Консультация по удалению",
        price1: 700,
        price2: 700,
        price3: 700,
      },
    ],
  },
  {
    id: 19,
    name: "Удаление новообразований кожи",
    img: "/images/services/7.png",
    description: `В нашей клинике проводится процедура удаления различных новообразований кожи на аппарате электрокоагуляции «Фотек», а так же хирургическим способом с забором материла для гистологического исследования.`,
    list: [
      {
        name: "Бородавка до5мм - 1шт.",
        price1: "-",
        price2: "-",
        price3: 500,
      },
      {
        name: "До5мм - 1шт.",
        price1: "-",
        price2: "-",
        price3: 800,
      },
      {
        name: "1см - 1шт.",
        price1: "-",
        price2: "-",
        price3: 1100,
      },
      {
        name: "Более 1см - 1шт.",
        price1: "-",
        price2: "-",
        price3: 2100,
      },
      {
        name: "Подошвенная бородавка - 1шт. 2000",
        price1: "-",
        price2: "-",
        price3: 2000,
      },
      {
        name: "Папиллома 1 шт.300",
        price1: 300,
        price2: "-",
        price3: 300,
      },
      {
        name: "от 3-5шт",
        price1: 800,
        price2: 800,
        price3: 800,
      },
      {
        name: "от 5-10шт",
        price1: 1000,
        price2: 1000,
        price3: 1000,
      },
      {
        name: "от 10-20шт",
        price1: 1500,
        price2: 1500,
        price3: 1500,
      },
      {
        name: "от 20-30шт",
        price1: 2000,
        price2: 2000,
        price3: 2000,
      },
      {
        name: "от 30шт",
        price1: 2500,
        price2: 2500,
        price3: 2500,
      },
      {
        name: "30-40шт",
        price1: 3000,
        price2: 3000,
        price3: 2500,
      },
      {
        name: "от 40-50шт",
        price1: 3500,
        price2: 3500,
        price3: 3500,
      },
      {
        name: "50-60шт",
        price1: 4000,
        price2: 4000,
        price3: 4000,
      },
      {
        name: "60-70шт",
        price1: 4500,
        price2: 4500,
        price3: 4500,
      },
      {
        name: "70-80шт",
        price1: 5000,
        price2: 5000,
        price3: 5000,
      },
      {
        name: "80-90шт",
        price1: 5500,
        price2: 5500,
        price3: 5500,
      },
      {
        name: "90-100шт",
        price1: 6000,
        price2: 6000,
        price3: 6000,
      },
      {
        name: "Удаление новообразования за единицу:",
        price1: "-",
        price2: "-",
        price3: "-",
      },
      {
        name: "До 3мм",
        price1: "-",
        price2: 700,
        price3: "-",
      },
      {
        name: "До 8мм",
        price1: "-",
        price2: 900,
        price3: "-",
      },
      {
        name: "До 1 см.",
        price1: "-",
        price2: 1200,
        price3: "-",
      },
      {
        name: "Более 1 см.",
        price1: "-",
        price2: 2200,
        price3: "-",
      },
      {
        name: "Дерматоскопия",
        price1: "-",
        price2: 500,
        price3: "-",
      },
      {
        name: "Анестезия",
        price1: 300,
        price2: 300,
        price3: 300,
      },
      {
        name: "Гистологическое исследование",
        price1: "-",
        price2: 2000,
        price3: "-",
      },
      {
        name: "Взятие анализа",
        price1: "-",
        price2: 300,
        price3: "-",
      },
      {
        name: "Удаление - Контагиозный моллюск",
        price1: "-",
        price2: 250,
        price3: 250,
      },
      {
        name: "Наложение шва",
        price1: "-",
        price2: 1800,
        price3: "-",
      },
      {
        name: "Снятие швов",
        price1: "-",
        price2: 800,
        price3: "-",
      },
      {
        name: "Панч биопсия",
        price1: "-",
        price2: 2000,
        price3: "-",
      },
      {
        name: "Наложение фиксирующей повязки 1шт.*50шт.",
        price1: "-",
        price2: 200,
        price3: "-",
      },
      // {
      //   name: "Скульптурный массаж",
      //   price1: 1400,
      //   price2: 1400,
      //   price3: 1400,
      // },
      // {
      //   name: "Скульптурный массаж с маской",
      //   price1: 1900,
      //   price2: 1900,
      //   price3: 1900,
      // },
      // {
      //   name: "Воротниковая зона",
      //   price1: "-",
      //   price2: 800,
      //   price3: 800,
      // },
      // {
      //   name: "Голова",
      //   price1: "-",
      //   price2: 500,
      //   price3: 500,
      // },
      // {
      //   name: "Ноги",
      //   price1: "-",
      //   price2: 1100,
      //   price3: 1100,
      // },
      // {
      //   name: "Руки / 1 рука",
      //   price1: "-",
      //   price2: "1000/600",
      //   price3: "1000/600",
      // },
      // {
      //   name: "Спина полностью",
      //   price1: "-",
      //   price2: 1100,
      //   price3: 1100,
      // },
      // {
      //   name: "Все тело (общий)",
      //   price1: "-",
      //   price2: 1800,
      //   price3: 1800,
      // },
      // {
      //   name: "Скульптурный массаж",
      //   price1: "-",
      //   price2: 1400,
      //   price3: 1400,
      // },
      // {
      //   name: "Скульптурный массаж с маской",
      //   price1: "-",
      //   price2: 1900,
      //   price3: 1900,
      // },
      // {
      //   name: "Лифт-трансформирующий массаж б/маски",
      //   price1: "-",
      //   price2: 2000,
      //   price3: 2000,
      // },
      // {
      //   name: "Лифт-трансформирующий массаж с маской",
      //   price1: "-",
      //   price2: 2500,
      //   price3: 2500,
      // },
      // {
      //   name: "Лифт-трансформирующий массаж с мешочками без маски",
      //   price1: "-",
      //   price2: 2300,
      //   price3: 2300,
      // },
      // {
      //   name: "Лифт-трансформирующий массаж с мешочками с маской",
      //   price1: "-",
      //   price2: 2800,
      //   price3: 2800,
      // },
      // {
      //   name: "Массаж «3Д» лицо без маски",
      //   price1: "-",
      //   price2: 1400,
      //   price3: 1400,
      // },
      // {
      //   name: "Массаж «3Д» с маской",
      //   price1: "-",
      //   price2: 1900,
      //   price3: 1900,
      // },
    ],
  },
  {
    id: 20,
    name: "Дерматоскопия",
    img: "/images/services/8.png",
    description: `Процедура осмотра новообразований кожи и кожных элементов сыпи с помощью дерматоскопа, что позволяет установить окончательный диагноз.`,
    list: [
      {
        name: "Дерматоскопия",
        price1: "-",
        price2: 500,
        price3: "-",
      },
      // {
      //   name: "Живот",
      //   price1: "-",
      //   price2: "-",
      //   price3: 700,
      // },
      // {
      //   name: "Ноги",
      //   price1: "-",
      //   price2: "-",
      //   price3: 1150,
      // },
      // {
      //   name: "Руки",
      //   price1: "-",
      //   price2: "-",
      //   price3: 600,
      // },
      // {
      //   name: "Спина",
      //   price1: "-",
      //   price2: "-",
      //   price3: 800,
      // },
      // {
      //   name: "Все тело",
      //   price1: "-",
      //   price2: "-",
      //   price3: 1800,
      // },
      // {
      //   name: "Все тело (детский)",
      //   price1: "-",
      //   price2: "-",
      //   price3: 1400,
      // },
      // {
      //   name: "Спина (детский)",
      //   price1: "-",
      //   price2: "-",
      //   price3: 800,
      // },
      // {
      //   name: "Массаж Лимфодренажный",
      //   price1: "-",
      //   price2: "-",
      //   price3: 1300,
      // },
      // {
      //   name: "Массаж Антицеллюлитный",
      //   price1: "-",
      //   price2: "-",
      //   price3: 1300,
      // },
    ],
  },
  {
    id: 21,
    name: "Биопсия кожи",
    img: "/images/services/9.png",
    description: `Процедура забора материала для гистологического исследования при подозрительных новообразованиях кожи, а так же хронических кожных заболеваниях для установки окончательного диагноза`,
    list: [
      {
        name: "Панч биопсия",
        price1: "-",
        price2: 2000,
        price3: "-",
      },
      // {
      //   name: "Морская прелюдия (Грязь+масло)",
      //   price1: "-",
      //   price2: "-",
      //   price3: 3000,
      // },
      // {
      //   name: "Морская прелюдия (Грязь+крем+скраб)",
      //   price1: "-",
      //   price2: "-",
      //   price3: 4000,
      // },
      // {
      //   name: "Морская прелюдия (Грязь+масло+скраб)",
      //   price1: "-",
      //   price2: "-",
      //   price3: 3500,
      // },
      // {
      //   name: "Морская прелюдия (Грязь+крем)",
      //   price1: "-",
      //   price2: "-",
      //   price3: 3500,
      // },
    ],
  },
  {
    id: 22,
    name: "Консультация",
    img: "/images/services/57.png",
    description: `Консультант трихолога проводится при наличии жалоб на перхоть, сухость кожи головы, шелушение, себорею, участки алопеции, выпадение волос. Врач осматривает состояние кожи головы, проводит специальные тесты и использует вспомогательные способы диагностики.`,
    list: [
      {
        name: "Консультация",
        price1: 1500,
        price2: 1500,
        price3: 1500,
      },
      {
        name: "Консультация повторная",
        price1: 500,
        price2: 500,
        price3: 500,
      },
      // {
      //   name: "Депиляция сахар губа",
      //   price1: 700,
      //   price2: 700,
      //   price3: 700,
      // },
      // {
      //   name: "Депиляция сахар бикини",
      //   price1: 2000,
      //   price2: 2000,
      //   price3: 2000,
      // },
      // {
      //   name: "Депиляция сахар подмышки",
      //   price1: 800,
      //   price2: 800,
      //   price3: 800,
      // },
      // {
      //   name: "Депиляция воск голень",
      //   price1: 1000,
      //   price2: 1000,
      //   price3: 1000,
      // },
      // {
      //   name: "Депиляция воск бедро",
      //   price1: 1000,
      //   price2: 1000,
      //   price3: 1000,
      // },
      // {
      //   name: "Депиляция воск руки",
      //   price1: 900,
      //   price2: 900,
      //   price3: 900,
      // },
    ],
  },
  {
    id: 23,
    name: "Лечение перхоти",
    img: "/images/services/58.png",
    description:
      "Перхоть -  это мелкие чешуйки на волосистой части головы, которые делают её внешний вид неопрятным. Каждый человек в своей жизни так или иначе сталкивается с этой проблемой. Чешуйки перхоти образуются в результате ускоренного роста кератиноцитов (основных клеток эпидермиса) во время обновления клеток кожи. Мы предлагаем Вам провести диагностику на аппарате АSW300 и на основе результатов назначить лечение.",
    list: [
      {
        name: "Консультация",
        price1: 1500,
        price2: 1500,
        price3: 1500,
      },
      {
        name: "Консультация повторная",
        price1: 500,
        price2: 500,
        price3: 500,
      },
      // {
      //   name: "Верхняя губа",
      //   price1: "-",
      //   price2: 1000,
      //   price3: "-",
      // },
      // {
      //   name: "Подбородок",
      //   price1: "-",
      //   price2: 1000,
      //   price3: "-",
      // },
      // {
      //   name: "Бакенбарды",
      //   price1: "-",
      //   price2: 1000,
      //   price3: "-",
      // },
      // {
      //   name: "Подмышки",
      //   price1: "-",
      //   price2: 2000,
      //   price3: "-",
      // },
      // {
      //   name: "Руки",
      //   price1: "-",
      //   price2: 2000,
      //   price3: "-",
      // },
      // {
      //   name: "Руки до локтя",
      //   price1: "-",
      //   price2: 1750,
      //   price3: "-",
      // },
      // {
      //   name: "Бикини глубокое",
      //   price1: "-",
      //   price2: 2500,
      //   price3: "-",
      // },
      // {
      //   name: "Бедра",
      //   price1: "-",
      //   price2: 2500,
      //   price3: "-",
      // },
      // {
      //   name: "Внутренняя поверхность бедер",
      //   price1: "-",
      //   price2: 1500,
      //   price3: "-",
      // },
      // {
      //   name: "Голени",
      //   price1: "-",
      //   price2: 2500,
      //   price3: "-",
      // },
      // {
      //   name: "Живот",
      //   price1: "-",
      //   price2: 2000,
      //   price3: "-",
      // },
      // {
      //   name: "Полоска на животе",
      //   price1: "-",
      //   price2: 1000,
      //   price3: "-",
      // },
      // {
      //   name: "БПГ",
      //   price1: "-",
      //   price2: 2500,
      //   price3: "-",
      // },
      // {
      //   name: "Подмышки+голень/бедра (2 позиции, 40 мин.)",
      //   price1: "-",
      //   price2: 4000,
      //   price3: "-",
      // },
      // {
      //   name: "Подмышки+бикини+бедра/голень (3 позиции, 1 час)",
      //   price1: "-",
      //   price2: 6000,
      //   price3: "-",
      // },
    ],
  },
  {
    id: 24,
    name: "Лечение выпадения волос",
    img: "/images/services/12.png",
    description: `Комплексное лечение выпадения волос направлено на проведение диагностических исследований, коррекцию дефицитов, подбор наружней и системной терапии. `,

    list: [
      {
        name: "Консультация",
        price1: 1500,
        price2: 1500,
        price3: 1500,
      },
      {
        name: "Консультация повторная",
        price1: 500,
        price2: 500,
        price3: 500,
      },
      // {
      //   name: "1 минута",
      //   price1: "-",
      //   price2: "-",
      //   price3: 40,
      // },
    ],
  },
  {
    id: 25,
    name: "Мезотерапия волосистой части головы",
    img: "/images/services/13.png",
    description: `Процедура введения мезотерапевтических препаратов внутрикожно в волосистую часть головы с целью поступления в волосяной фолликул питательных веществ, витаминов, микроэлементов. Процедура рекомендуется для укрепления волос, уменьшения выпадения, лечения перхоти`,
    list: [
      {
        name: "Мезохаейр/Швейцария 2,5мл",
        price1: 4000,
        price2: 4000,
        price3: 4000,
      },
      {
        name: "Мезохаейр/Швейцария 5мл",
        price1: 7000,
        price2: 7000,
        price3: 7000,
      },
      {
        name: "Мезо-Дженезис Meso-Genesis BP3/Испания 5мл.",
        price1: 6500,
        price2: 6500,
        price3: 6500,
      },
      {
        name: "Мезо-Дженезис Meso-Genesis BP3/Испания 2,5 мл.",
        price1: 3500,
        price2: 3500,
        price3: 3500,
      },
      // {
      //   name: "Бородавка (1 шт.)",
      //   price1: 500,
      //   price2: "-",
      //   price3: "-",
      // },
      // {
      //   name: "Папилома (1 шт.)",
      //   price1: 300,
      //   price2: 300,
      //   price3: 300,
      // },
      // {
      //   name: "Папилома (от 3-5 шт.)",
      //   price1: 800,
      //   price2: 800,
      //   price3: 800,
      // },
      // {
      //   name: "Папилома (от 5-10 шт.)",
      //   price1: 1000,
      //   price2: 1000,
      //   price3: 1000,
      // },
      // {
      //   name: "Папилома (от 10-20 шт.)",
      //   price1: 1500,
      //   price2: 1500,
      //   price3: 1000,
      // },
      // {
      //   name: "Папилома (от 20-30 шт.)",
      //   price1: 2000,
      //   price2: 2000,
      //   price3: 2000,
      // },
      // {
      //   name: "Папилома (от 30 шт.)",
      //   price1: 2500,
      //   price2: 2500,
      //   price3: 2500,
      // },
      // {
      //   name: "Папилома (30-40 шт.)",
      //   price1: 3000,
      //   price2: 3000,
      //   price3: 3000,
      // },
      // {
      //   name: "Папилома (40-50 шт.)",
      //   price1: 3500,
      //   price2: 3500,
      //   price3: 3500,
      // },
      // {
      //   name: "Папилома (50-60 шт.)",
      //   price1: 4000,
      //   price2: 4000,
      //   price3: 4000,
      // },
      // {
      //   name: "Папилома (60-70 шт.)",
      //   price1: 4500,
      //   price2: 4500,
      //   price3: 4500,
      // },
      // {
      //   name: "Папилома (70-80 шт.)",
      //   price1: 5000,
      //   price2: 5000,
      //   price3: 5000,
      // },
      // {
      //   name: "Папилома (80-90 шт.)",
      //   price1: 5500,
      //   price2: 5500,
      //   price3: 5500,
      // },
      // {
      //   name: "Папилома (90-100 шт.)",
      //   price1: 6000,
      //   price2: 6000,
      //   price3: 6000,
      // },
      // {
      //   name: "Удаление новообразования за единицу (До 3 мм.)",
      //   price1: "-",
      //   price2: 500,
      //   price3: "-",
      // },
      // {
      //   name: "Удаление новообразования за единицу (До 8 мм.)",
      //   price1: "-",
      //   price2: 800,
      //   price3: "-",
      // },
      // {
      //   name: "Удаление новообразования за единицу (До 1 см.)",
      //   price1: "-",
      //   price2: 1100,
      //   price3: "-",
      // },
      // {
      //   name: "Удаление новообразования за единицу (Более 1 см.)",
      //   price1: "-",
      //   price2: 2100,
      //   price3: "-",
      // },
      // {
      //   name: "Дерматоскопия",
      //   price1: "-",
      //   price2: 300,
      //   price3: "-",
      // },
      // {
      //   name: "Анестезия",
      //   price1: "-",
      //   price2: 300,
      //   price3: "-",
      // },
      // {
      //   name: "Гистологическое исследование",
      //   price1: "-",
      //   price2: 2000,
      //   price3: "-",
      // },
      // {
      //   name: "Взятие анализа",
      //   price1: "-",
      //   price2: 300,
      //   price3: "-",
      // },
      // {
      //   name: "Удаление - Контагиозный моллюск",
      //   price1: "-",
      //   price2: 250,
      //   price3: "-",
      // },
      // {
      //   name: "Наложение шва",
      //   price1: "-",
      //   price2: 1300,
      //   price3: "-",
      // },
      // {
      //   name: "Снятие швов",
      //   price1: "-",
      //   price2: 800,
      //   price3: "-",
      // },
      // {
      //   name: "Панч биопсия",
      //   price1: "-",
      //   price2: 1500,
      //   price3: "-",
      // },
    ],
  },
  {
    id: 26,
    name: "Плазмотерапия волосистой части головы",
    img: "/images/services/14.png",
    description: `Процедура введения плазмы, полученной из крови самого пациента, внутрикожно в волосистую часть головы с целью поступления в волосяной фолликул питательных веществ и микроэлементов. Процедура рекомендуется для укрепления волос, уменьшения выпадения, лечения перхоти.`,
    list: [
      {
        name: "Кортексил волосистая часть головы",
        price1: 5500,
        price2: 5500,
        price3: "-",
      },
      {
        name: "Плазмалифтинг 1 пробирка",
        price1: 3500,
        price2: 3500,
        price3: "-",
      },
      {
        name: "Плазмалифтинг 2 пробирка",
        price1: 6000,
        price2: 6000,
        price3: "-",
      },
      {
        name: "Плазмалифтинг 3 пробирка",
        price1: 8500,
        price2: 8500,
        price3: "-",
      },
    ],
  },
  {
    id: 27,
    name: "Чистки и уходовые процедуры",
    img: "/images/services/16.png",
    description: `Чистка лица - процедура, направленная на удаление комедонов и очищение пор. Процедура включает в себя несколько этапов: очищение, предпилинговая подготовка, пилинг, механическая ручная чистка, подсушивающие или увлажняющие маски, тонизация. 
В нашей клинике чистка лица проводится на косметике GiGi и Holy Land.
Уходовые процедуры направлены на увлажнение кожи, мягкое отшелушивание, тонизацию, насыщение тканей кислородом и питательными веществами.
В нашей клинике представлен широкий выбор уходовых процедур, которые выполняются на косметике GiGi и Holy Land. Каждая процедура имеет свои показания и выбирается после консультации специалиста.`,
    list: [
      {
        name: "Чистка HL",
        price1: 3000,
        price2: 3000,
        price3: 3000,
      },
      {
        name: "Чистка спины",
        price1: 4000,
        price2: 4000,
        price3: 4000,
      },
      {
        name: "Чистка груди",
        price1: 4000,
        price2: 4000,
        price3: 4000,
      },
      {
        name: "Чистка GiGi",
        price1: 3500,
        price2: 3500,
        price3: 3500,
      },
      {
        name: "Уход Имидж",
        price1: 4000,
        price2: 4000,
        price3: 4000,
      },
      {
        name: "Уход HL",
        price1: 3500,
        price2: 3500,
        price3: 3500,
      },
      {
        name: "Уход GiGi",
        price1: 3400,
        price2: 3400,
        price3: 3400,
      },
      {
        name: "Уход GiGi лифтинговый",
        price1: 5100,
        price2: 5100,
        price3: 5100,
      },
      {
        name: "Уход GiGi карбокси",
        price1: 5500,
        price2: 5500,
        price3: 5500,
      },
      {
        name: "Уход GiGi G4",
        price1: 5500,
        price2: 5500,
        price3: 5500,
      },
      {
        name: "Уход GiGi Пептидное омоложение",
        price1: "-",
        price2: 5000,
        price3: "-",
      },
      {
        name: "Чистка+ G4/Пептидное омоложение",
        price1: 7000,
        price2: 7000,
        price3: 7000,
      },
      {
        name: "Уход GiGi Биоплазма",
        price1: 5500,
        price2: 5500,
        price3: 5500,
      },
      {
        name: "Карбокси + чистка",
        price1: 6000,
        price2: 6000,
        price3: 6000,
      },
      {
        name: "Шелковые волокна",
        price1: 4000,
        price2: 4000,
        price3: 4000,
      },
    ],
  },
  {
    id: 28,
    name: "Химические пилинги",
    img: "/images/services/17.png",
    description: `В нашей клинике представлен большой выбор химических пилингов. 
Химические пилинги – это процедура обновления кожи с использованием специальных химических составов. Они могут применяться для решения различных проблем, таких как акне, пигментация, морщины и другие признаки старения кожи. Пилинг стимулирует естественные процессы регенерации кожи, что приводит к улучшению ее текстуры, цвета и общего состояния.
`,
    list: [
      {
        name: "Чистка HL АБР",
        price1: 3400,
        price2: 3400,
        price3: 3400,
      },
      {
        name: "АБР",
        price1: 3000,
        price2: 3000,
        price3: 3000,
      },
      {
        name: "АБР спины",
        price1: 3400,
        price2: 3400,
        price3: 3400,
      },
      {
        name: "АБР + чистка спины",
        price1: 4000,
        price2: 4000,
        price3: 4000,
      },
      {
        name: "Альфа комплекс HL",
        price1: 3000,
        price2: 3000,
        price3: 3000,
      },
      {
        name: "Биорепил Gi Gi",
        price1: 3500,
        price2: 3500,
        price3: 3500,
      },
      {
        name: "PRX - 33",
        price1: 5000,
        price2: 5000,
        price3: 5000,
      },
      {
        name: "Желтый пилинг",
        price1: 4000,
        price2: 4000,
        price3: 4000,
      },
      {
        name: "Желтый пилинг Retises CT",
        price1: "-",
        price2: 4000,
        price3: "-",
      },
      {
        name: "ТСА пилинг",
        price1: 4500,
        price2: 4500,
        price3: 4500,
      },
      {
        name: "ТСА GiGi",
        price1: 3500,
        price2: 3500,
        price3: 3500,
      },
      {
        name: "Травяной пилинг от Шрамик Фреш",
        price1: 5500,
        price2: "-",
        price3: "-",
      },
      {
        name: "Травяной пилинг от Шрамик Классик",
        price1: 6000,
        price2: "-",
        price3: "-",
      },
      {
        name: "Пиллинг Джесснера",
        price1: "-",
        price2: 3500,
        price3: "-",
      },
    ],
  },
  {
    id: 30,
    name: "RF лифтинг терапия на аппарате RUBICA RF",
    img: "/images/services/18.png",
    description: `Процедура проводится на аппарате RUBICA RF, задача которой заключается в глубоком проникновении радиочастот без повреждения кожного покрова, в результате чего происходит сокращение эластино- и коллагеносодержащих волокон, а также запускается процесс образования новых клеток. После курса процедур заметно подтягиваются овал лица, приподнимаются брови, носогубные складки становятся менее выражены. Кожа становится более упругой, выравнивается цвет и тон лица.`,
    list: [
      {
        name: "RF с маской",
        price1: 2500,
        price2: "-",
        price3: "-",
      },
      {
        name: "RF без маской",
        price1: 2100,
        price2: "-",
        price3: "-",
      },
      {
        name: "1/2 RF",
        price1: 600,
        price2: "-",
        price3: "-",
      },

      // {
      //   name: "Хлебина Ольга Викторовна Первичный приём",
      //   price1: "-",
      //   price2: 2000,
      //   price3: "-",
      // },
      // {
      //   name: "Хлебина Ольга Викторовна Вторичный приём (в течение месяца)",
      //   price1: "-",
      //   price2: 1500,
      //   price3: "-",
      // },
      // {
      //   name: "Ильясова Светлана Николаевна Первичный приём",
      //   price1: "-",
      //   price2: 1500,
      //   price3: "-",
      // },
      // {
      //   name: "Ильясова Светлана Николаевна Вторичный приём (в течение месяца)",
      //   price1: "-",
      //   price2: 1000,
      //   price3: "-",
      // },
      // {
      //   name: "УЗИ",
      //   price1: "-",
      //   price2: 1200,
      //   price3: "-",
      // },
      // {
      //   name: "Кольпоскопия",
      //   price1: "-",
      //   price2: 1000,
      //   price3: "-",
      // },
      // {
      //   name: "Забор анализа",
      //   price1: "-",
      //   price2: 300,
      //   price3: "-",
      // },
      // {
      //   name: "Фемофлор 13",
      //   price1: "-",
      //   price2: 2500,
      //   price3: "-",
      // },
      // {
      //   name: "Фемофлор 16",
      //   price1: "-",
      //   price2: 2500,
      //   price3: "-",
      // },
    ],
  },
  {
    id: 31,
    name: "Микротоки на аппарате Bio-Ultimate Gold",
    img: "/images/services/19.png",
    description: `Процедура проводится на аппарате Bio-Ultimate Gold. Во время процедуры происходит воздействие на кожу, мышцы и лимфатическую систему мягким импульсным током низкого напряжения. Процедура обладает выраженным лимфодренажным действием, улучшает цвет лица, подтягивает овал и сокращает морщины.`,
    list: [
      {
        name: "Микротоки без маски",
        price1: 1600,
        price2: "-",
        price3: "-",
      },
      {
        name: "Микротоки с маской",
        price1: 2000,
        price2: "-",
        price3: "-",
      },
      {
        name: "Микротоки глаз",
        price1: 700,
        price2: "-",
        price3: "-",
      },
      // {
      //   name: "SkinElly Meso Extra 1мл.",
      //   price1: "-",
      //   price2: 10000,
      //   price3: "-",
      // },
      // {
      //   name: "SkinElly Meso Extra Великобритания 2мл.",
      //   price1: "-",
      //   price2: 18000,
      //   price3: "-",
      // },
      // {
      //   name: "SkinElly Meso Extra Великобритания 3мл.",
      //   price1: "-",
      //   price2: 27000,
      //   price3: "-",
      // },
      // {
      //   name: "SkinElly LIGHT 1мл.",
      //   price1: "-",
      //   price2: 12000,
      //   price3: "-",
      // },
      // {
      //   name: "SkinElly LIGHT Великобритания 2мл.",
      //   price1: "-",
      //   price2: 22000,
      //   price3: "-",
      // },
      // {
      //   name: "SkinElly Middle 1мл.",
      //   price1: "-",
      //   price2: 13000,
      //   price3: "-",
      // },
      // {
      //   name: "SkinElly Middle Великобритания 2мл.",
      //   price1: "-",
      //   price2: 24000,
      //   price3: "-",
      // },
      // {
      //   name: "SkinElly Middle Великобритания 3мл.",
      //   price1: "-",
      //   price2: 36000,
      //   price3: "-",
      // },
      // {
      //   name: "SkinElly INTENSE 1мл.",
      //   price1: "-",
      //   price2: 14000,
      //   price3: "-",
      // },
      // {
      //   name: "SkinElly INTENSE Великобритания 2мл.",
      //   price1: "-",
      //   price2: 26000,
      //   price3: "-",
      // },
      // {
      //   name: "Repart Deep 1мл. Россия",
      //   price1: "-",
      //   price2: 10000,
      //   price3: "-",
      // },
      // {
      //   name: "Repart Deep 2мл. Россия",
      //   price1: "-",
      //   price2: 17000,
      //   price3: "-",
      // },
      // {
      //   name: "Repart Normal 1мл. Россия",
      //   price1: "-",
      //   price2: 10000,
      //   price3: "-",
      // },
      // {
      //   name: "Repart Normal 2мл. Россия",
      //   price1: "-",
      //   price2: 14000,
      //   price3: "-",
      // },
    ],
  },
  {
    id: 32,
    name: "Лазерная эпиляция",
    img: "/images/services/20.png",
    description: `Лазерная эпиляция- это метод радикального удаления волос, предполагающий разрушение волосяных фолликулов с помощью лазерного излучения.
Производится на аппарате SOPRANO TITANIUM, которой сочетает в себе сразу 3 вида лазерного излучения: александритовое, диодное и неодимовое. С помощью
этой технологии можно удалить волосы любого цвета и на любом участке тела. Метод безвреден, так как лазер действует только на меланин и корень, не повреждая окружающие ткани. А так же считается эффективным и долгосрочным, так как может обеспечить длительный период без необходимости повторного удаления волос`,
    list: [
      { name: "Верхняя губа", price1: "-", price2: 1000, price3: "-" },
      { name: "Подбородок", price1: "-", price2: 1000, price3: "-" },
      { name: "Бакенбарды", price1: "-", price2: 1000, price3: "-" },
      { name: "Подмышки", price1: "-", price2: 2000, price3: "-" },
      { name: "Соски", price1: "-", price2: 2000, price3: "-" },
      { name: "Руки", price1: "-", price2: 2000, price3: "-" },
      { name: "Руки до локтя", price1: "-", price2: 1750, price3: "-" },
      { name: "Бикини глубокое", price1: "-", price2: 2500, price3: "-" },
      { name: "Бикини классическое", price1: "-", price2: 2000, price3: "-" },
      { name: "Бедра", price1: "-", price2: 2500, price3: "-" },
      {
        name: "Внутренняя поверхность бедер",
        price1: "-",
        price2: 1500,
        price3: "-",
      },
      { name: "Голени", price1: "-", price2: 2500, price3: "-" },
      { name: "Живот", price1: "-", price2: 2000, price3: "-" },
      { name: "Полоска на животе", price1: "-", price2: 1000, price3: "-" },
      // { name: "БПГ", price1: "-", price2: 2500, price3: "-" },
      {
        name: "Подмышки+голень/бедра (2позиции 40мин.)",
        price1: "-",
        price2: 4000,
        price3: "-",
      },
      {
        name: "Бикини+голень (2позиции,40 мин)",
        price1: "-",
        price2: 5000,
        price3: "-",
      },
      {
        name: "Подмышки+голень+бедра/голень (3позиц.1час)",
        price1: "-",
        price2: 6000,
        price3: "-",
      },
      {
        name: "Бедра+голень+бикини классическое(3 позиции, 1час)",
        price1: "-",
        price2: 6500,
        price3: "-",
      },
      {
        name: "Голень+бедра+бикини (3позиц.1час)",
        price1: "-",
        price2: 7000,
        price3: "-",
      },
      {
        name: "Подмышки+бикини+бедра+голень(4позиц.1,5час)",
        price1: "-",
        price2: 8500,
        price3: "-",
      },
      { name: "Бикини мужское", price1: "-", price2: 4000, price3: "-" },
      // {
      //   name: "Консультация",
      //   price1: "-",
      //   price2: "-",
      //   price3: 300,
      // },
      // {
      //   name: "Маникюр",
      //   price1: "-",
      //   price2: "-",
      //   price3: 600,
      // },
      // {
      //   name: "Педикюр",
      //   price1: "-",
      //   price2: "-",
      //   price3: 1800,
      // },
      // {
      //   name: "Педикюр 1/2",
      //   price1: "-",
      //   price2: "-",
      //   price3: 1200,
      // },
      // {
      //   name: "Гель лак",
      //   price1: "-",
      //   price2: "-",
      //   price3: 500,
      // },
      // {
      //   name: "Обычный лак",
      //   price1: "-",
      //   price2: "-",
      //   price3: 200,
      // },
      // {
      //   name: "Диабетическая стопа",
      //   price1: "-",
      //   price2: "-",
      //   price3: 2000,
      // },
      // {
      //   name: "Обработка грибкового 1 ногтя",
      //   price1: "-",
      //   price2: "-",
      //   price3: 300,
      // },
      // {
      //   name: "Обработка грибковых ногтей от 4 шт.",
      //   price1: "-",
      //   price2: "-",
      //   price3: 1800,
      // },
      // {
      //   name: "Обработка грибковых ногтей+ стопа",
      //   price1: "-",
      //   price2: "-",
      //   price3: 2700,
      // },
      // {
      //   name: "Кюретаж вросшего ногтя",
      //   price1: "-",
      //   price2: "-",
      //   price3: 850,
      // },
      // {
      //   name: "Удаление мозоля",
      //   price1: "-",
      //   price2: "-",
      //   price3: 750,
      // },
      // {
      //   name: "Обработка бородавки",
      //   price1: "-",
      //   price2: "-",
      //   price3: 400,
      // },
      // {
      //   name: "Обработка трещин",
      //   price1: "-",
      //   price2: "-",
      //   price3: 900,
      // },
      // {
      //   name: "Тампонирование",
      //   price1: "-",
      //   price2: "-",
      //   price3: 350,
      // },
      // {
      //   name: "Онихолизиса",
      //   price1: "-",
      //   price2: "-",
      //   price3: 200,
      // },
      // {
      //   name: "Парафиновая ванна",
      //   price1: "-",
      //   price2: "-",
      //   price3: 250,
      // },
      // {
      //   name: "Пластина «Подофикс»",
      //   price1: "-",
      //   price2: "-",
      //   price3: 270,
      // },
      // {
      //   name: "Панариций",
      //   price1: "-",
      //   price2: "-",
      //   price3: 600,
      // },
      // {
      //   name: "Снятие гель лака",
      //   price1: "-",
      //   price2: "-",
      //   price3: 100,
      // },
      // {
      //   name: "Перевязка",
      //   price1: "-",
      //   price2: "-",
      //   price3: 150,
      // },
    ],
  },
  {
    id: 33,
    name: "Восковая эпиляция",
    img: "/images/services/21.png",
    description: `Восковая эпиляция - это метод удаления волос путем нанесения и удаления специального воска на кожу. В процессе восковой эпиляции воск наносится на кожу в направлении роста волос, затем специальной полоской или самим воском удаляется против роста волос, захватывая в себя волоски и их корни. Этот метод эпиляции считается эффективным и обеспечивает гладкость кожи на длительный период времени, так как вместе с волосками удаляется и их корень, что замедляет их рост.`,
    list: [
      {
        name: "Депиляция воск голень",
        price1: 1000,
        price2: 1000,
        price3: 1000,
      },
      {
        name: "Депиляция воск бедро",
        price1: 1000,
        price2: 1000,
        price3: 1000,
      },
      {
        name: "Депиляция воск руки",
        price1: 900,
        price2: 900,
        price3: 900,
      },

      // {
      //   name: "Пинцет/воск",
      //   price1: "-",
      //   price2: "-",
      //   price3: 800,
      // },
      // {
      //   name: "Пинцет/воск+ окрашивание ( краска, хна)",
      //   price1: "-",
      //   price2: "-",
      //   price3: 1000,
      // },
      // {
      //   name: "Окрашивание ресниц",
      //   price1: "-",
      //   price2: "-",
      //   price3: 500,
      // },
      // {
      //   name: "Ламинирование бровей",
      //   price1: "-",
      //   price2: "-",
      //   price3: 1000,
      // },
      // {
      //   name: "Ламинирование и окрашивание бровей",
      //   price1: "-",
      //   price2: "-",
      //   price3: 2000,
      // },
      // {
      //   name: "Ламинирование бровей",
      //   price1: "-",
      //   price2: "-",
      //   price3: 900,
      // },
      // {
      //   name: "Протеиновое восстановление бровей",
      //   price1: "-",
      //   price2: "-",
      //   price3: 1500,
      // },
      // {
      //   name: "Ламинирование и окрашивание ресниц",
      //   price1: "-",
      //   price2: "-",
      //   price3: 2000,
      // },
      // {
      //   name: "Нанесение ухода при окрашивании",
      //   price1: "-",
      //   price2: "-",
      //   price3: 300,
      // },
    ],
  },
  {
    id: 34,
    name: "Сахарная эпиляция",
    img: "/images/services/59.png",
    description: `Сахарная эпиляция, также известная как шугаринг, - это метод удаления волос, который использует специальную смесь сахара, воды и лимонного сока для удаления волос. В процессе сахарной эпиляции смесь сахара наносится на кожу в направлении роста волос, затем резко удаляется против роста волос, захватывая в себя волоски и их корни. Этот метод эпиляции считается более мягким и менее болезненным по сравнению с восковой эпиляцией, так как сахарная паста обладает мягкой текстурой и не прилипает к коже. Кроме того, сахарная эпиляция имеет ряд преимуществ, таких как меньший риск раздражения кожи и возможность использования натуральных ингредиентов.
`,
    list: [
      {
        name: "Депиляция сахар губа",
        price1: 700,
        price2: 700,
        price3: 700,
      },
      {
        name: "Депиляция сахар бикини",
        price1: 2000,
        price2: 2000,
        price3: 2000,
      },
      {
        name: "Депиляция сахар подмышки",
        price1: 800,
        price2: 800,
        price3: 800,
      },

      // {
      //   name: "Коррекция нарощенных ресниц",
      //   price1: "-",
      //   price2: "-",
      //   price3: 1200,
      // },
    ],
  },
  {
    id: 35,
    name: "Электроэпиляция",
    img: "/images/services/60.png",
    description: `Электроэпиляция - это метод удаления волос, при котором используется электрический ток для уничтожения волосяного фолликула. В процессе электроэпиляции тонкая игла вводится в фолликул волоса, после чего через нее подается электрический ток, который разрушает фолликул. Этот метод эпиляции считается долгосрочным, так как способен уничтожить волосяной фолликул и предотвратить рост волос на длительный период времени. Однако электроэпиляция может быть болезненной и требует профессионального обучения и опыта для безопасного и эффективного выполнения.`,
    list: [
      {
        name: "Электроэпиляция 1 минута 40",
        price1: "-",
        price2: "-",
        price3: 50,
      },

      // {
      //   name: "Прокол  ушей с  сережками",
      //   price1: "-",
      //   price2: "-",
      //   price3: 1300,
      // },
    ],
  },
  {
    id: 36,
    name: "Прессотерапия на аппарате LYMPHA PRESS",
    img: "/images/services/24.png",
    description: `это процедура массажа на аппарате LYMPHA PRESS , суть которого заключается в механическом воздействии на тело сжатым воздухом. Благодаря активации микроциркуляции, нормализации оттока лимфы процедура запускает обменные процессы, способствует омоложению клеток, помогает избавиться от застойных явлений и токсинов.`,
    list: [
      {
        name: "Прессотерапия на во время маски",
        price1: "-",
        price2: 300,
        price3: 300,
      },
      {
        name: "Прессотерапия на 25 мин.",
        price1: "-",
        price2: 500,
        price3: 500,
      },
      {
        name: "Прессотерапия на 45 мин.",
        price1: "-",
        price2: 600,
        price3: 600,
      },
      {
        name: "Прессотерапия на перед Старвак",
        price1: "-",
        price2: 400,
        price3: 400,
      },

      // {
      //   name: "1 минута",
      //   price1: "-",
      //   price2: "-",
      //   price3: 30,
      // },
    ],
  },
  {
    id: 37,
    name: "EMS скульптор на аппарате EMS SCULPT",
    img: "/images/services/25.png",
    description: `процедура электромагнитной терапии на аппарате EMS SCULPT, которая позволяет обрабатывать проблемные зоны, одновременно укреплять мускулатуру и добиваться лифтинг-эффекта.В результате более двадцати тысяч сокращений мышечных волокон во время процедуры происходит рост мышцы и повышение ее тонуса.
`,
    list: [
      {
        name: "1 насадка",
        price1: "-",
        price2: "2000 Акция! 1000",
        price3: "-",
      },
      {
        name: "2 насадки",
        price1: "-",
        price2: "2500 Акция! 1500",
        price3: "-",
      },
      {
        name: "3 насадки",
        price1: "-",
        price2: "3000 Акция! 2000",
        price3: "-",
      },
      {
        name: "4 насадки",
        price1: "-",
        price2: "3000 Акция! 2000",
        price3: "-",
      },
      {
        name: "1 насадка / Курс от 5 процедур скидка 30%",
        price1: "-",
        price2: 7500,
        price3: "-",
      },
      {
        name: "2 насадки / Курс от 5 процедур скидка 30%",
        price1: "-",
        price2: 10500,
        price3: "-",
      },
      {
        name: "3 насадки / Курс от 5 процедур скидка 30%",
        price1: "-",
        price2: 13500,
        price3: "-",
      },
      {
        name: "4 насадки / Курс от 5 процедур скидка 30%",
        price1: "",
        price2: 15000,
        price3: "-",
      },
    ],
  },
  {
    id: 38,
    name: "Старвак",
    img: "/images/services/26.png",
    description: `Старвак (Starvac) - эффективная методика вакуумно-роликового массажа кожных покровов.
Этот метод может быть эффективным в борьбе с «лишними» сантиметрами и улучшении внешнего вида кожи.
Во время процедуры на аппарате Старвак с помощью роликов улучшается кровообращение, стимулируется лимфодренаж и повышается тонус кожи.
Наилучший результат получается после проведения курса!
`,
    list: [
      {
        name: "Старвак (вакуумный массаж)",
        price1: "-",
        price2: 1200,
        price3: 1200,
      },
      {
        name: "Старвак 1 / 2(вакуумный массаж)",
        price1: "-",
        price2: 900,
        price3: 900,
      },
    ],
  },
  {
    id: 39,
    name: "Ручной массаж",
    img: "/images/services/27.png",
    description: `Ручной массаж — это метод мануальной терапии, который включает в себя различные техники воздействия на мягкие ткани тела с целью улучшения физического и эмоционального состояния человека. Он может быть использован для расслабления, лечения, реабилитации или просто для повышения общего тонуса.
`,
    list: [
      { name: "Воротниковая зона", price1: "-", price2: 1000, price3: 1000 },
      { name: "Голова", price1: "-", price2: 500, price3: 500 },
      { name: "Ноги", price1: "-", price2: 1100, price3: 1100 },
      { name: "Стопы", price1: "-", price2: 600, price3: 600 },
      {
        name: "Руки / 1 рука",
        price1: "-",
        price2: "1000 / 600 ",
        price3: "1000/ 600",
      },
      { name: "Спина полностью", price1: "-", price2: 1500, price3: 1500 },
      { name: "Все тело (общий)", price1: "-", price2: 2200, price3: 2200 },
      { name: "Скульптурный массаж", price1: 1400, price2: 1400, price3: 1400 },
      {
        name: "Скульптурный массаж с маской",
        price1: 1900,
        price2: 1900,
        price3: 1900,
      },
      {
        name: "Лифт- омолаживающий массаж с б/маски",
        price1: "-",
        price2: 2000,
        price3: 2000,
      },
      {
        name: "Лифт-омолаживающий массаж с маской",
        price1: "-",
        price2: 2500,
        price3: 2500,
      },
      { name: "Все тело (детский)", price1: "-", price2: 1500, price3: 1500 },
      { name: "Спина (детский)", price1: "-", price2: 800, price3: 800 },
      {
        name: "Массаж Лимфодренажный",
        price1: "-",
        price2: 1600,
        price3: 1600,
      },
      {
        name: "Массаж Антицеллюлитный",
        price1: "-",
        price2: 1300,
        price3: 1300,
      },
      // { name: "Массаж медовый", price1: "-", price2: "-", price3: "-" },
      { name: "Массаж медовый Живот", price1: "-", price2: "-", price3: 700 },
      { name: "Массаж медовый Ноги", price1: "-", price2: "-", price3: 1150 },
      { name: "Массаж медовый Руки", price1: "-", price2: "-", price3: 600 },
      { name: "Массаж медовый Спина", price1: "-", price2: "-", price3: 800 },
      {
        name: "Массаж медовый Все тело",
        price1: "-",
        price2: "-",
        price3: 1800,
      },
    ],
  },
  {
    id: 40,
    name: "Антицеллюлитный",
    img: "/images/services/29.png",
    description: `Антицеллюлитный массаж — это специальная техника массажа, направленная на уменьшение проявлений целлюлита и улучшение состояния кожи. Целлюлит (или "апельсиновая корка") — это изменение структуры жировой ткани, которое приводит к образованию неровностей на коже, чаще всего на бедрах, ягодицах и животе`,
    list: [
      { name: "Воротниковая зона", price1: "-", price2: 1000, price3: 1000 },
      { name: "Голова", price1: "-", price2: 500, price3: 500 },
      { name: "Ноги", price1: "-", price2: 1100, price3: 1100 },
      { name: "Стопы", price1: "-", price2: 600, price3: 600 },
      {
        name: "Руки / 1 рука",
        price1: "-",
        price2: "1000 / 600 ",
        price3: "1000/ 600",
      },
      { name: "Спина полностью", price1: "-", price2: 1500, price3: 1500 },
      { name: "Все тело (общий)", price1: "-", price2: 2200, price3: 2200 },
      { name: "Скульптурный массаж", price1: 1400, price2: 1400, price3: 1400 },
      {
        name: "Скульптурный массаж с маской",
        price1: 1900,
        price2: 1900,
        price3: 1900,
      },
      {
        name: "Лифт- омолаживающий массаж с б/маски",
        price1: "-",
        price2: 2000,
        price3: 2000,
      },
      {
        name: "Лифт-омолаживающий массаж с маской",
        price1: "-",
        price2: 2500,
        price3: 2500,
      },
      { name: "Все тело (детский)", price1: "-", price2: 1500, price3: 1500 },
      { name: "Спина (детский)", price1: "-", price2: 800, price3: 800 },
      {
        name: "Массаж Лимфодренажный",
        price1: "-",
        price2: 1600,
        price3: 1600,
      },
      {
        name: "Массаж Антицеллюлитный",
        price1: "-",
        price2: 1300,
        price3: 1300,
      },
      { name: "Массаж медовый", price1: "-", price2: "-", price3: "-" },
      { name: "Живот", price1: "-", price2: "-", price3: 700 },
      { name: "Ноги", price1: "-", price2: "-", price3: 1150 },
      { name: "Руки", price1: "-", price2: "-", price3: 600 },
      { name: "Спина", price1: "-", price2: "-", price3: 800 },
      { name: "Все тело", price1: "-", price2: "-", price3: 1800 },
    ],
  },
  {
    id: 41,
    name: "Лимфодренажный",
    img: "/images/services/30.png",
    description: `Лимфодренажный массаж — это специальная техника массажа, направленная на улучшение лимфатического оттока и уменьшение отеков в тканях. Он помогает активизировать лимфатическую систему, что способствует выведению токсинов и лишней жидкости из организма.
`,
    list: [
      { name: "Воротниковая зона", price1: "-", price2: 1000, price3: 1000 },
      { name: "Голова", price1: "-", price2: 500, price3: 500 },
      { name: "Ноги", price1: "-", price2: 1100, price3: 1100 },
      { name: "Стопы", price1: "-", price2: 600, price3: 600 },
      {
        name: "Руки / 1 рука",
        price1: "-",
        price2: "1000 / 600 ",
        price3: "1000/ 600",
      },
      { name: "Спина полностью", price1: "-", price2: 1500, price3: 1500 },
      { name: "Все тело (общий)", price1: "-", price2: 2200, price3: 2200 },
      { name: "Скульптурный массаж", price1: 1400, price2: 1400, price3: 1400 },
      {
        name: "Скульптурный массаж с маской",
        price1: 1900,
        price2: 1900,
        price3: 1900,
      },
      {
        name: "Лифт- омолаживающий массаж с б/маски",
        price1: "-",
        price2: 2000,
        price3: 2000,
      },
      {
        name: "Лифт-омолаживающий массаж с маской",
        price1: "-",
        price2: 2500,
        price3: 2500,
      },
      { name: "Все тело (детский)", price1: "-", price2: 1500, price3: 1500 },
      { name: "Спина (детский)", price1: "-", price2: 800, price3: 800 },
      {
        name: "Массаж Лимфодренажный",
        price1: "-",
        price2: 1600,
        price3: 1600,
      },
      {
        name: "Массаж Антицеллюлитный",
        price1: "-",
        price2: 1300,
        price3: 1300,
      },
      { name: "Массаж медовый", price1: "-", price2: "-", price3: "-" },
      { name: "Живот", price1: "-", price2: "-", price3: 700 },
      { name: "Ноги", price1: "-", price2: "-", price3: 1150 },
      { name: "Руки", price1: "-", price2: "-", price3: 600 },
      { name: "Спина", price1: "-", price2: "-", price3: 800 },
      { name: "Все тело", price1: "-", price2: "-", price3: 1800 },
    ],
  },
  {
    id: 42,
    name: "Лечебный",
    img: "/images/services/31.png",
    description: `Лечебный массаж — это специальная методика массажа, направленная на лечение различных заболеваний и состояний, а также на восстановление функций организма после травм или операций. Он сочетает в себе как элементы классического массажа, так и специфические техники, адаптированные под нужды пациента.
`,
    list: [
      { name: "Воротниковая зона", price1: "-", price2: 1000, price3: 1000 },
      { name: "Голова", price1: "-", price2: 500, price3: 500 },
      { name: "Ноги", price1: "-", price2: 1100, price3: 1100 },
      { name: "Стопы", price1: "-", price2: 600, price3: 600 },
      {
        name: "Руки / 1 рука",
        price1: "-",
        price2: "1000 / 600 ",
        price3: "1000/ 600",
      },
      { name: "Спина полностью", price1: "-", price2: 1500, price3: 1500 },
      { name: "Все тело (общий)", price1: "-", price2: 2200, price3: 2200 },
      { name: "Скульптурный массаж", price1: 1400, price2: 1400, price3: 1400 },
      {
        name: "Скульптурный массаж с маской",
        price1: 1900,
        price2: 1900,
        price3: 1900,
      },
      {
        name: "Лифт- омолаживающий массаж с б/маски",
        price1: "-",
        price2: 2000,
        price3: 2000,
      },
      {
        name: "Лифт-омолаживающий массаж с маской",
        price1: "-",
        price2: 2500,
        price3: 2500,
      },
      { name: "Все тело (детский)", price1: "-", price2: 1500, price3: 1500 },
      { name: "Спина (детский)", price1: "-", price2: 800, price3: 800 },
      {
        name: "Массаж Лимфодренажный",
        price1: "-",
        price2: 1600,
        price3: 1600,
      },
      {
        name: "Массаж Антицеллюлитный",
        price1: "-",
        price2: 1300,
        price3: 1300,
      },
      { name: "Массаж медовый", price1: "-", price2: "-", price3: "-" },
      { name: "Живот", price1: "-", price2: "-", price3: 700 },
      { name: "Ноги", price1: "-", price2: "-", price3: 1150 },
      { name: "Руки", price1: "-", price2: "-", price3: 600 },
      { name: "Спина", price1: "-", price2: "-", price3: 800 },
      { name: "Все тело", price1: "-", price2: "-", price3: 1800 },
    ],
  },
  {
    id: 43,
    name: "Омолаживающий",
    img: "/images/services/32.png",
    description: `Омолаживающий массаж — это косметическая процедура, направленная на улучшение состояния кожи, уменьшение видимости морщин и общее омоложение лица и шеи. Этот вид массажа сочетает в себе различные техники и приемы, которые помогают активизировать кровообращение, улучшить тонус и эластичность кожи.
`,
    list: [
      { name: "Воротниковая зона", price1: "-", price2: 1000, price3: 1000 },
      { name: "Голова", price1: "-", price2: 500, price3: 500 },
      { name: "Ноги", price1: "-", price2: 1100, price3: 1100 },
      { name: "Стопы", price1: "-", price2: 600, price3: 600 },
      {
        name: "Руки / 1 рука",
        price1: "-",
        price2: "1000 / 600 ",
        price3: "1000/ 600",
      },
      { name: "Спина полностью", price1: "-", price2: 1500, price3: 1500 },
      { name: "Все тело (общий)", price1: "-", price2: 2200, price3: 2200 },
      { name: "Скульптурный массаж", price1: 1400, price2: 1400, price3: 1400 },
      {
        name: "Скульптурный массаж с маской",
        price1: 1900,
        price2: 1900,
        price3: 1900,
      },
      {
        name: "Лифт- омолаживающий массаж с б/маски",
        price1: "-",
        price2: 2000,
        price3: 2000,
      },
      {
        name: "Лифт-омолаживающий массаж с маской",
        price1: "-",
        price2: 2500,
        price3: 2500,
      },
      { name: "Все тело (детский)", price1: "-", price2: 1500, price3: 1500 },
      { name: "Спина (детский)", price1: "-", price2: 800, price3: 800 },
      {
        name: "Массаж Лимфодренажный",
        price1: "-",
        price2: 1600,
        price3: 1600,
      },
      {
        name: "Массаж Антицеллюлитный",
        price1: "-",
        price2: 1300,
        price3: 1300,
      },
      { name: "Массаж медовый", price1: "-", price2: "-", price3: "-" },
      { name: "Живот", price1: "-", price2: "-", price3: 700 },
      { name: "Ноги", price1: "-", price2: "-", price3: 1150 },
      { name: "Руки", price1: "-", price2: "-", price3: 600 },
      { name: "Спина", price1: "-", price2: "-", price3: 800 },
      { name: "Все тело", price1: "-", price2: "-", price3: 1800 },
    ],
  },
  {
    id: 44,
    name: "Кедровая бочка",
    img: "/images/services/33.png",
    description: `Кедровая бочка — это большая емкость, в которую подается горячий пар. Поэтому её ещё называют фитобочкой.\n
Её оздоровительный эффект значительно превосходит эффект от обычной бани или сауны.\n
Организм:\n
    - омолаживается;\n
    - очищается от шлаков и токсинов;\n
    - снимается ощущение хронической усталости;\n
    - повышается иммунитет;\n
    - восстанавливаются физические силы;\n
    - появляется лёгкость и хорошее настроение.`,
    list: [
      {
        name: "Кедровая бочка",
        price1: "-",
        price2: "-",
        price3: "1 минута - 50руб, 10мин - 500",
      },
    ],
  },
  {
    id: 45,
    name: "Обертывания",
    img: "/images/services/61.png",
    description: `Обертывания — это косметическая процедура, которая включает применение различных средств на кожу всего тела или отдельных его участков, за которыми следует обертывание пленкой или тканью. Обертывания могут использоваться для достижения различных целей, таких как улучшение состояния кожи, уменьшение целлюлита, детоксикация, увлажнение и омоложение.
`,
    list: [
      {
        name: "Морская прелюдия (Грязь+масло)",
        price1: "-",
        price2: "-",
        price3: 3000,
      },
      {
        name: "Морская прелюдия (Грязь+крем+скраб)",
        price1: "-",
        price2: "-",
        price3: 4000,
      },
      {
        name: "Морская прелюдия (Грязь+масло+скраб)",
        price1: "-",
        price2: "-",
        price3: 3500,
      },
      {
        name: "Морская прелюдия (Грязь+крем)",
        price1: "-",
        price2: "-",
        price3: 3500,
      },
    ],
  },
  {
    id: 46,
    name: "Анализы",
    img: "/images/services/62.png",
    description: `К нас в клинике Вы можете провести «золотой стандарт" лабораторных исследований гинекологических инфекций:
•Посев (бактериологическое исследование).
•Бактериоскопия (мазок на флору).
•Определение антител в крови (серологический анализ).
•ДНК-диагностика (метод ПЦР).
Анализы выполняются после консультации врача-гинеколога.
`,
    list: [
      {
        name: "Кольпоскопия",
        price1: "-",
        price2: 1000,
        price3: "-",
      },
      {
        name: "Забор анализа",
        price1: "-",
        price2: 300,
        price3: "-",
      },
      {
        name: "Мазок на онкоцитологию",
        price1: "-",
        price2: 400,
        price3: "-",
      },
      {
        name: "ВПЧ высокого онкогенного риска",
        price1: "-",
        price2: 1300,
        price3: "-",
      },
      {
        name: "Neisseria gonorrhoeae (соскоб)",
        price1: "-",
        price2: 300,
        price3: "-",
      },
      {
        name: "Trichomonas vaginalis (соскоб)",
        price1: "-",
        price2: 300,
        price3: "-",
      },
      {
        name: "Chlamidia Tranchomatis (соскоб)",
        price1: "-",
        price2: 300,
        price3: "-",
      },
      {
        name: "Mycoplasma genitalium (соскоб)",
        price1: "-",
        price2: 300,
        price3: "-",
      },
      {
        name: "Фемофлор 13",
        price1: "-",
        price2: 2500,
        price3: "-",
      },
      {
        name: "Фемофлор 16",
        price1: "-",
        price2: 2500,
        price3: "-",
      },
    ],
  },
  {
    id: 47,
    name: "Ультразвуковое исследование",
    img: "/images/services/36.png",
    description: `УЗИ органов малого таза — процедура, которую назначают как женщинам, так и мужчинам. При проведении исследования оценивают состояние и функциональность мочевыводящей и половой систем. Процедура безопасна для пациента, не доставляет дискомфорта или болезненных ощущений.`,
    list: [
      {
        name: "Узи",
        price1: "-",
        price2: 1200,
        price3: "-",
      },
    ],
  },
  {
    id: 48,
    name: "Интимная пластика, филлеры",
    img: "/images/services/37.png",
    description: `Те эстетические проблемы интимного характера, которые ранее можно было решить только хирургическим путем, сегодня успешно устраняются с помощью инъекций гиалуроновой кислоты. Интимная контурная пластика пользуется все большей популярностью. В ходе процедуры в интимную зону вводится специальный препарат на основе гиалуроновой кислоты - полностью биосовместимого вещества. которое содержится в тканях организма человека. Процедура позволяет скорректировать форму наружных половых органов, решить многие эстетические нюансы.
`,
    list: [
      {
        name: "SkinElly Meso Extra 1мл.",
        price1: "-",
        price2: 10000,
        price3: "-",
      },
      {
        name: "SkinElly Meso Extra Великобритания 2мл.",
        price1: "-",
        price2: 18000,
        price3: "-",
      },
      {
        name: "SkinElly Meso Extra Великобритания 3мл.",
        price1: "-",
        price2: 27000,
        price3: "-",
      },
      {
        name: "SkinElly LIGHT 1мл.",
        price1: "-",
        price2: 12000,
        price3: "-",
      },
      {
        name: "SkinElly LIGHT Великобритания 2мл.",
        price1: "-",
        price2: 22000,
        price3: "-",
      },
      {
        name: "SkinElly Middle 1мл.",
        price1: "-",
        price2: 13000,
        price3: "-",
      },
      {
        name: "SkinElly Middle Великобритания 2мл.",
        price1: "-",
        price2: 24000,
        price3: "-",
      },
      {
        name: "SkinElly Middle Великобритания 3мл.",
        price1: "-",
        price2: 36000,
        price3: "-",
      },
      {
        name: "SkinElly INTENSE 1мл.",
        price1: "-",
        price2: 14000,
        price3: "-",
      },
      {
        name: "SkinElly INTENSE Великобритания 2мл.",
        price1: "-",
        price2: 26000,
        price3: "-",
      },
      {
        name: "Repart Deep 1мл. Россия",
        price1: "-",
        price2: 10000,
        price3: "-",
      },
      {
        name: "Repart Deep 2мл. Россия",
        price1: "-",
        price2: 17000,
        price3: "-",
      },
      {
        name: "Repart Normal 1мл. Россия",
        price1: "-",
        price2: 10000,
        price3: "-",
      },
      {
        name: "Repart Normal 2мл. Россия",
        price1: "-",
        price2: 14000,
        price3: "-",
      },
      {
        name: "Интимное осветление на аппарате ИНМОД от 5000",
        price1: "-",
        price2: "от 5000",
        price3: "-",
      },
    ],
  },
  {
    id: 49,
    name: "Консультация",
    img: "/images/services/38.png",
    description: `Гинеколог – специалист, который проводит диагностику, профилактику и лечение широкого перечня заболеваний женской репродуктивной системы. Этот врач принимает как девочек, которые еще не ведут половую жизнь, так и девушек и зрелых женщин (в том числе в период менопаузы). При необходимости специалист предотвращает хронизацию патологических процессов и их осложнения.
`,
    list: [
      {
        name: "Хлебина Ольга Викторовна Первичный приём",
        price1: "-",
        price2: 2300,
        price3: "-",
      },
      {
        name: "Хлебина Ольга Викторовна Вторичный приём (в течение месяца)",
        price1: "-",
        price2: 2000,
        price3: "-",
      },
      {
        name: "Ильясова Светлана Николаевна Первичный приём",
        price1: "-",
        price2: 2000,
        price3: "-",
      },
      {
        name: "Ильясова Светлана Николаевна Вторичный приём (в течение месяца)",
        price1: "-",
        price2: 1500,
        price3: "-",
      },
    ],
  },
  {
    id: 50,
    name: "УЗИ",
    img: "/images/services/36.png",
    description: `Исследования можно проводить у людей разных возрастных групп и на разных этапах заболевания.
С помощью ультразвуковой диагностической системы DV-N3 MINDRAY, соответствующей профессиональным клиническим требованиям по проведению ультразвуковой диагностики, можно получить информацию о локализации, форме, размерах, структуре и двигательной активности внутренних органов`,
    list: [
      {
        name: "Узи",
        price1: "-",
        price2: 1200,
        price3: "-",
      },
    ],
  },
  {
    id: 51,
    name: "Урология",
    img: "/images/services/39.png",
    description: `Врач-уролог занимается диагностикой, профилактикой и лечением заболеваний мочеполовой системы у мужчин и женщин. Урология охватывает широкий спектр медицинских дисциплин. 
Основные направления работы врача-уролога:  
⦁	амбулаторная урология;
⦁	интимная контурная пластика;
⦁ не хирургическое лечение преждевременной эякуляциии увеличение полового члена;
⦁	укрепление мышцтазового дна;
⦁	плазмолифтинг интимной зоны;
⦁	ультразвуковаядиагностика.
`,
    list: [
      {
        name: "Духовнова Кристина Михайловна Прием",
        price1: "-",
        price2: 2000,
        price3: "-",
      },
      {
        name: "Узи Щитовидной железы",
        price1: "-",
        price2: 1000,
        price3: "-",
      },
      {
        name: "Узи Молочных желез",
        price1: "-",
        price2: 1000,
        price3: "-",
      },
      {
        name: "Узи Брюшной полости",
        price1: "-",
        price2: 1500,
        price3: "-",
      },
      {
        name: "Узи Печени",
        price1: "-",
        price2: 1000,
        price3: "-",
      },
      {
        name: "Узи Желчного пузыря",
        price1: "-",
        price2: 1000,
        price3: "-",
      },
      {
        name: "Узи Поджелудочной железы",
        price1: "-",
        price2: 1000,
        price3: "-",
      },
      {
        name: "Узи Почек, надпочечников",
        price1: "-",
        price2: 1000,
        price3: "-",
      },
      {
        name: "ТРУЗИ Предстательной железы",
        price1: "-",
        price2: 1200,
        price3: "-",
      },
      {
        name: "Узи Мошонки",
        price1: "-",
        price2: 1000,
        price3: "-",
      },
    ],
  },
  {
    id: 52,
    name: "Капельницы молодости и красоты",
    img: "/images/services/41.png",
    description: `Капельницы красоты и молодости – эффективный метод антивозрастной терапии, позволяют доставить все полезные вещества непосредственно в кровоток, а не растерять большую их часть при прохождении через пищеварительную систему. 
Курсовая терапия с помощью омолаживающих коктейлей окажет достаточно быстрый и заметный эффект: придаст коже сияние, ногтям и волосам прочность и красоту, уменьшит выраженность морщин, пигментных пятен и других дефектов.
`,
    list: [
      {
        name: "Молодость и красота",
        price1: 4000,
        price2: 4000,
        price3: "-",
      },
      {
        name: "Молодость и красота(курс 3 процедуры, 1-2 раза в неделю)",
        price1: 11500,
        price2: 11500,
        price3: "-",
      },
    ],
  },
  {
    id: 53,
    name: "Нутрициология",
    img: "/images/services/42.png",
    description: `Нутрициология – наука о питании. Название происходит от латинского слова nutritio – «питание». Нутрициология изучает все, что имеет отношение к пище и вопросам питания: белки, жиры, углеводы, витамины и микроэлементы, их взаимодействие, усвоение, расходование, выведение и то, какое влияние все это оказывает на здоровье и качество жизни человека. На консультации специалист выявит необходимость обследования, порекомендует перечень анализов, скорректирует дефициты. 
`,
    list: [
      { name: "Первичный прием1ч.", price1: 2000, price2: 2000, price3: "-" },
      { name: "Вторичный прием 30м", price1: 1500, price2: 1500, price3: "-" },
      {
        name: "Прием перед капельницей 30мин",
        price1: 1500,
        price2: 1500,
        price3: "-",
      },
      // { name: "Капельницы по назначению доктора", price1: "-", price2: "-", price3: "-" },
      { name: "Детокс 1 флакон", price1: 3300, price2: 3300, price3: "-" },
      { name: "Детокс 2 флакона", price1: 4500, price2: 4500, price3: "-" },
      {
        name: "Детокс- (курс 3 процедур 1 раз в 3-5дней)",
        price1: 10000,
        price2: 10000,
        price3: "-",
      },
      {
        name: "Анемия под контролем",
        price1: "3500",
        price2: 3500,
        price3: "-",
      },
      {
        name: "Анемия под контролем 2 флакона",
        price1: 5500,
        price2: 5500,
        price3: "-",
      },
      { name: "Диабет под контролем", price1: 3800, price2: 3800, price3: "-" },
      {
        name: "Диабет под контролем (курс 5 процедур 1 раз в 5 дней)",
        price1: 17500,
        price2: 17500,
        price3: "-",
      },
    ],
  },
  {
    id: 54,
    name: "Консультация",
    img: "/images/services/43.png",
    description: `В нашей клинике прием ведет опытный эндокринолог-нутрициолог, который не только подберет вам полноценный сбалансированный рацион питания, но и проведет качественную диагностику вашего здоровья.
`,
    list: [
      { name: "Первичный прием1ч.", price1: 2000, price2: 2000, price3: "-" },
      { name: "Вторичный прием 30м", price1: 1500, price2: 1500, price3: "-" },
      {
        name: "Прием перед капельницей 30мин",
        price1: 1500,
        price2: 1500,
        price3: "-",
      },
      // { name: "Капельницы по назначению доктора", price1: "-", price2: "-", price3: "-" },
      { name: "Детокс 1 флакон", price1: 3300, price2: 3300, price3: "-" },
      { name: "Детокс 2 флакона", price1: 4500, price2: 4500, price3: "-" },
      {
        name: "Детокс- (курс 3 процедур 1 раз в 3-5дней)",
        price1: 10000,
        price2: 10000,
        price3: "-",
      },
      {
        name: "Анемия под контролем",
        price1: "3500",
        price2: 3500,
        price3: "-",
      },
      {
        name: "Анемия под контролем 2 флакона",
        price1: 5500,
        price2: 5500,
        price3: "-",
      },
      { name: "Диабет под контролем", price1: 3800, price2: 3800, price3: "-" },
      {
        name: "Диабет под контролем (курс 5 процедур 1 раз в 5 дней)",
        price1: 17500,
        price2: 17500,
        price3: "-",
      },
    ],
  },
  {
    id: 55,
    name: "Наращивание ресниц",
    img: "/images/services/44.png",
    description: `Наращивание ресниц — это процедура, которая позволяет увеличить длину и объем натуральных ресниц с помощью искусственных материалов. Существует несколько методов наращивания ресниц, включая:
⦁	Классическое наращивание: подразумевает приклеивание одной искусственной ресницы к одной натуральной. Этот метод создает эффект естественных ресниц.
⦁	2D, 3D, 4D и более: в этом методе на каждую натуральную ресницу приклеивается несколько искусственных, что создает более объемный и эффектный вид.
`,
    list: [
      {
        name: "Наращивание ресниц",
        price1: "-",
        price2: "-",
        price3: 1800,
      },
      {
        name: "Коррекция нарощенных ресниц",
        price1: "-",
        price2: "-",
        price3: 1200,
      },
    ],
  },
  //   {
  //     id: 56,
  //     name: "Оформление бровей",
  //     img: "/images/services/45.png",
  //     description: `— это процедура, направленная на создание аккуратной формы бровей, что помогает подчеркнуть глаза и улучшить общий внешний вид лица. Существует несколько методов оформления бровей:
  // ⦁	Эпиляция: удаление лишних волосков с помощью воска, пинцета или нитки.
  // ⦁	Окрашивание: использование краски для бровей, чтобы сделать их более выразительными и подчеркнуть форму`,
  //     list: [
  //       {
  //         name: "1 минута",
  //         price1: "-",
  //         price2: "-",
  //         price3: 30,
  //       },
  //     ],
  //   },
  {
    id: 57,
    name: "Прокол ушей",
    img: "/images/services/47.png",
    description: `— это процедура, которая позволяет создать отверстия в мочках ушей для ношения серег. Это одна из самых распространенных процедур в косметологии.
1. Методы прокола:
⦁	Прокол с помощью пистолета: быстро и безболезненно, с использованием стерильных серег.
⦁	Прокол иглой: более традиционный метод, который может быть менее болезненным для некоторых людей и позволяет лучше контролировать глубину прокола.
`,
    list: [
      {
        name: "Прокол ушей",
        price1: "-",
        price2: "-",
        price3: 800,
      },
      {
        name: "Прокол ушей с сережками",
        price1: "-",
        price2: "-",
        price3: 1300,
      },
    ],
  },
  {
    id: 58,
    name: "Консультация подолога",
    img: "/images/services/48.png",
    description: `Основные аспекты консультации подолога:
Цели консультации:
⦁	Определение состояния здоровья ног и стоп.
⦁	Диагностика заболеваний, таких как грибковые инфекции, натоптыши, мозоли, плоскостопие, вросшие ногти и др.
⦁	Консультация по вопросам ухода за ногами, профилактики заболеваний и выбора подходящей обуви.
⦁	Разработка индивидуальных рекомендаций по лечению и уходу.
Показания для консультации:
⦁	Боль в стопах или ногах.
⦁	Изменения в состоянии кожи (шелушение, покраснение, зуд).
⦁	Проблемы с ногтями (вросшие ногти, изменение цвета или формы).
⦁	Усталость и дискомфорт при ходьбе.
`,
    list: [
      {
        name: "Консультация",
        price1: "-",
        price2: "-",
        price3: 500,
      },
      {
        name: "Диабетическая стопа",
        price1: "-",
        price2: "-",
        price3: 2400,
      },
      {
        name: "Обработка грибкового 1 ногтя",
        price1: "-",
        price2: "-",
        price3: 300,
      },
      {
        name: "Обработка грибковых ногтей от 4 шт.",
        price1: "-",
        price2: "-",
        price3: 2100,
      },
      {
        name: "Обработка грибковых ногтей+ стопа",
        price1: "-",
        price2: "-",
        price3: 3000,
      },
      {
        name: "Кюретаж вросшего ногтя",
        price1: "-",
        price2: "-",
        price3: 1000,
      },
      {
        name: "Удаление мозоля",
        price1: "-",
        price2: "-",
        price3: 750,
      },
      {
        name: "Обработка бородавки",
        price1: "-",
        price2: "-",
        price3: 400,
      },
      {
        name: "Обработка трещин",
        price1: "-",
        price2: "-",
        price3: 1200,
      },
      {
        name: "Тампонирование",
        price1: "-",
        price2: "-",
        price3: 350,
      },
      {
        name: "Онихолизиса",
        price1: "-",
        price2: "-",
        price3: 200,
      },
      {
        name: "Парафиновая ванна",
        price1: "-",
        price2: "-",
        price3: 250,
      },
      {
        name: "Пластина «Подофикс»",
        price1: "-",
        price2: "-",
        price3: 3000,
      },
      {
        name: "Панариций",
        price1: "-",
        price2: "-",
        price3: 600,
      },
      {
        name: "Снятие гель лака",
        price1: "-",
        price2: "-",
        price3: 100,
      },
      {
        name: "Перевязка",
        price1: "-",
        price2: "-",
        price3: 150,
      },
    ],
  },
  {
    id: 60,
    name: "Маникюр",
    img: "/images/services/49.png",
    description: `Маникюр это косметическая процедура по обработке ногтей на пальцах рук и самих кистей рук. 
Процедура включает в себя удаление кутикулы, обработку ногтевой пластины, придание формы ногтям и нанесение лака или геля. 
`,
    list: [
      {
        name: "Маникюр",
        price1: "-",
        price2: "-",
        price3: 600,
      },
      {
        name: "Педикюр",
        price1: "-",
        price2: "-",
        price3: 2100,
      },
      {
        name: "Педикюр мужской",
        price1: "-",
        price2: "-",
        price3: 2500,
      },
      {
        name: "Педикюр 1/2",
        price1: "-",
        price2: "-",
        price3: 1500,
      },
      {
        name: "Гель лак",
        price1: "-",
        price2: "-",
        price3: 500,
      },
      {
        name: "Обычный лак",
        price1: "-",
        price2: "-",
        price3: 200,
      },
    ],
  },
];
